.top-heading {
  height: 5vh;
}

.service-page {
  height: 95vh;
}

.content {
  width: auto;
  padding-left: 20px;
  padding-right: 11px;
  height: 100%;
}

.notAvailable {
  padding: 250px;
}

.vendor-service-list-table>.table-heading>thead {
  border: 2px solid var(--bs-table-border-color);
}

.vendor-service-list-table>.table-heading>thead>tr>th {
  border-style: solid !important;
}

.services-table-outer-div {
  background-color: var(--white-color);
  border: 1px solid var(--step-icon-bg);
  border-radius: 6px;
}

.status-active {
  width: 33px;
  height: 17px;
  border-radius: 25px;
  border: 1px solid var(--sura-text-color);
  background: linear-gradient(var(--sura-text-color), var(--custom-link));
}

.status-deactive {
  width: 33px;
  height: 17px;
  border-radius: 25px;
  border: 1px solid var(--service-status-deactive);
  background: var(--step-icon-bg);
}

.activate {
  width: 15px;
  height: 15px;
  border-radius: 25px;
  background: var(--white-color);
  margin-left: 16px;
}

.deactivate {
  width: 15px;
  height: 15px;
  border-radius: 25px;
  background: var(--white-color);
  margin-left: 0px;
}

.action_edit {
  color: var(--sura-text-color);
  cursor: pointer;
}

.action_delete {
  color: var(--card-btn-text-color);
}

.filter-input-feild-div {
  position: relative;
}

.filter-input-feild-service,
.user-filter-input {
  background-color: var(--search-filter-input-feild-bg);
  height: 2.3em;
  padding: 0 11px;
  border: 1px solid var(--warning-container-border);
  border-radius: 5px;
  width: 100%;
  margin-top: 2px;

}

.filter-input-feild-service:focus,
.user-filter-input:focus {
  outline: none;
  border-color: var(--warning-container-border);
}

.user-booking-filter-input-feild-date {
  background-color: #F8F8F8;
  height: 2.9em;
  padding: 0 5px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  width: 100%;
  max-width: 8vw;
  margin-top: 2px;
  font-size: 12px;
}

.user-filter-select {
  margin-top: 2px;
  height: 2.5em !important;
  max-width: 28vw !important;
  width: 100%;
  border-radius: 8px;
}

.tdclass {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.tdclass-number {
  font-size: 14px;
  font-weight: 300;
  text-align: right;
}

.tdclass-number .hl05eU {
  justify-content: right;
}

.status-button-center {
  display: flex;
  justify-content: left;
}

.thclass {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}

.thclass-number {
  font-size: 15px;
  font-weight: 600;
}

.filterSvg {
  margin-left: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 550;
}

.clear-filter {
  width: 100% !important;
  height: 35px !important;
  font-size: 12px !important;
  background: var(--search-input-color) !important;
  color: var(--black-color) !important;
  box-shadow: 0 2px 5px var(--select-item-box-shadow);
}


.service-filter-select {
  margin-top: 2px;
  height: 2.5em;
  max-width: 10vw !important;
  width: 100%;
}

.service-filter-select>.ant-select-selector {
  border-radius: 5px !important;
}

.service-filter-select>.ant-select-arrow .anticon {
  font-size: 14px !important;
}

@media only screen and (max-width: 820px) {
  .content {
    width: auto;
  }

  .status-button-center {
    margin-left: 8px;
  }

  .top-heading {
    height: 5vh;
  }

  .filter-input-feild-service {
    background-color: var(--search-filter-input-feild-bg);
    height: 2.3em;
    padding: 0px 8px 0 22px;
    border: 1px solid var(--warning-container-border);
    border-radius: 5px;
    width: 80px;
    margin-top: 2px;
  }

  .clear-filter {
    width: 85px !important;
    height: 35px !important;
    font-size: 12px !important;
    background: var(--search-input-color) !important;
    color: var(--black-color) !important;
    box-shadow: 0 2px 5px var(--select-item-box-shadow);
  }

  .service-filter-select {
    margin-top: 2px;
    min-width: 81px;
    height: 2.5em;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .content {
    width: auto;
  }

  .clear-filter {
    width: 86px !important;
    height: 35px !important;
    font-size: 12px !important;
    background: var(--search-input-color) !important;
    color: var(--black-color) !important;
    box-shadow: 0 2px 5px var(--select-item-box-shadow);
  }


}

.ant-pagination .ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: var(--antd-pagination-a);
  background: var(--warning-container-border);
  border-radius: 5px;
}

.ant-pagination .ant-pagination-item-active a {
  color: var(--white-text-color);
  background: var(--sura-text-color);
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: var(--warning-container-border);
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
  border-color: none;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: var(--team-card-bg-color);
  border-color: var(--sura-text-color);
}

.scroller-container {
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  border: 1px solid var(--search-btn);
  margin: 0 2px;
  cursor: pointer;
}

.ant-pagination-item-active {
  background-color: var(--ant-pagination-item-active-bg);
  border-color: var(--ant-pagination-item-active-bg);
  color: var(--white-text-color);
}

.form-input-number::-webkit-inner-spin-button,
.form-input-number::-webkit-outer-spin-button,
.form-input-number::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.ant-form-item .ant-form-item-label>label {
  font-size: 15px !important;
}