.vendor_review_row .inner-review {
  border-radius: 5px;
  outline: 1px solid gainsboro;
  padding: 20px;
  text-align: center;
}

.vendor_review_progress_bar {
  outline: 1px solid gainsboro;
  border-radius: 5px;
  padding: 10px;
}

.vendor-search-display-desc-container4-progress1 {
  display: flex;
  background-color: var(--container4-progress1);
  border-radius: 20px;
  position: relative;
  margin: 5px 0;
  height: 16px;
  width: 250px;
  opacity: 1;
}

.review-user-name {
  text-transform: capitalize;
  font-weight: 600;
}

.user-bookings-tab-active,
.user-bookings-tab-active:hover {
  background-color: var(--hotpink-border-color);
  color: var(--white-text-color);

}

.vendor-search-display-desc-container4-progress1-done {
  height: 15px;
  background: var(--progress-bar-fill-color);
  box-shadow: 0 3px 3px -5px var(--progress-bar-box-shadow-color), 0 2px 5px var(--progress-bar-box-shadow-color);
  border-radius: 20px;
  color: var(--white-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.vendor-review-btn-outer-div .user-booking-tab-outer-div .user-bookings-tab {
  font-size: 16px;
  font-weight: bold;

}

.vendor-review-active-btn,
.vendor-review-active-btn:hover {
  cursor: pointer;
  border-color: var(--review-rating-active-btn) !important;
  background-color: var(--review-rating-active-btn) !important;
  color: var(--white-text-color) !important;
}

.vendor-review-deActive-btn,
.vendor-review-deActive-btn:hover {
  cursor: pointer;
  border-color: var(--review-rating-active-btn) !important;
  color: var(--review-rating-active-btn) !important;
}

.vendor_review_container {
  background-color: var(--white-color);
  padding: 10px 15px 0px 15px;
  margin-top: 10px;
}

.vendor_review-star {
  color: var(--star-color);
}

.vendor_review_review {
  height: 70px;
  overflow-x: auto;
}

.vendor-average-star {
  font-size: 24px;
  color: var(--star-color);

}

.vendor-review-img-div {

  padding: 10px;
}

.vendor-review-img-div img {
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.vendor_review_review::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color) !important;
  border-radius: 1em;
}

.vendor_review_review::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color) !important;
}

.vendor_review_review::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--warning-container-bg-color) !important;
}

@media screen and (max-width:944px) {
  .vendor_review_progress_bar {
    margin-top: 10px;
  }
}

@media screen and (max-width:785px) {
  .vendor_review_rating_avg {
    display: flex;
  }

  .vendor_review-star {
    margin-left: 3px;
    margin-top: 2px;
  }

  .vendor-review-img-div img {
    height: 70px;
    width: 70px;
    border-radius: 50%;
  }
}

@media screen and (max-width:500px) {
  .vendor_review_rating_avg {
    float: right;
  }

  .vendor_review_progress_bar {
    margin-top: 10px;
  }
}

@media screen and (max-width:280px) {
  .vendor_review_progress_bar {
    margin-top: 22%;
  }

  .vendor_review_name {
    margin-top: 14px;
  }

  .vendor_review_name_desc {
    margin-top: 14px;
  }

  .vendor_review_row {
    margin-top: 30px;
  }

  .vendor_review_lowest_button {
    font-size: 8px;
    font-weight: bold;
  }

  .vendor_review_highest_button {
    font-size: 8px;
    font-weight: bold;
  }

  .vendor_review_most_button {
    font-size: 8px;
    font-weight: bold;
  }
}

.user-booking-tab-outer-div .disabled {
  letter-spacing: 0.8px;
  padding: 5px 9px;
  margin-right: 6px;
  border-radius: 15px;
  font-weight: 600;
  letter-spacing: 0.8px;
  padding: 5px 9px;
  border: 1px solid hotpink !important;
  margin-right: 6px;
  border-radius: 15px;
}

@media (max-width:575px) {
  .calendar-row>.vendor-review-btn-outer-div>.user-booking-tab-outer-div>.user-bookings-tab {
    font-size: 14px;
    margin: 5px;
  }
}

@media (max-width:575px) {
  .inner-review>.col-md-5 {
    font-size: 16px;
  }

  .shop-cart-dd-service>.cart-image>.col-9 {
    width: 100% !important;
  }

  .vendor_review_container>.row {
    padding-left: 0px !important;

  }

  .vendor-review-img-div {
    display: flex;
    justify-content: center;
  }

  .vendor-review-img-div img {
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }

  .cart-image>.col-9>div>div>h5 {
    font-size: 15px;
  }

  .inner-review>.col-md-5>h2 {
    font-size: 15px;
  }

  .inner-review>.col-md-5>h2>.vendor-average-star {
    font-size: 15px;
  }

  .vendor_review_progress_bar>div>.row>.col-md-4>div {
    font-size: 15px;
  }

  .vendor-search-display-desc-container4-progress1 {
    height: 12px;
    width: 180px;
  }

  .vendor-search-display-desc-container4-progress1-done {
    height: 11px;
  }

  .vendor_review_review {
    font-size: 14px;
  }

  .vendor_review_review {
    height: auto;
    overflow-x: auto;
    max-height: 70px;
  }
}

@media (max-width:353px) {
  .vendor-search-display-desc-container4-progress1 {
    height: 11px;
    width: 120px;
  }

  .vendor-search-display-desc-container4-progress1-done {
    height: 10px;

  }
}

@media (max-width:1254px) and (min-width:992px) {
  .vendor-search-display-desc-container4-progress1 {
    width: 200px;
  }
}