
.cashback-container {
    justify-content: center;
    margin: auto !important;
}
.cashback-container .container {        
    max-width: 600px;
    padding: 20px;
    background: var(--white-btn-bg-color);
    border-radius: 4px;
    text-align: center;
  }
  .cashback-container .section {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px var(--cashback-container-box-shadow);
  }
  .custom-bg-color{
    background-color:#f0faff;
  }
  .custom-bg-color2{
    background-color:#fff3f3;
  }
  .custom-bg-color3{
    background-color:#f0faff;
  }
  .cashback-container .section-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  .cashback-container .amount {
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    color: var(--input-field-color);
  }
  .cashback-container .currency-symbol {
    font-size: 24px;
  }
  .cashback-container .available-cashback {
    font-size: 16px;
    color: var(--dark-gray-color);
  }
@media screen and (max-width:427px) {
    .cashback-container {
        font-size: 13px;
    }
}
@media screen and (max-width:358px) {
    .cashback-container {
        font-size: 9px;
        font-weight: 500;
    }
}
@media screen and (min-width: 768px) and (max-width: 960px) {
    .cashback-container {
        font-size: 0.75rem;
        font-weight: 500;
    }
}
.user-cashback-content {
    padding-left: 20px;
    padding-right: 10px;
}
@media (max-width:767px) {
    .cashback-container .container {        
        max-width: 800px;
        padding: 0px;
      }
      .user-cashback-content {
        padding-left: 0px;
        padding-right: 0px;
    }
}