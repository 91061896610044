@keyframes marquee {
  100% {
    transform: translateX(100%);
  }
  0% {
    transform: translateX(-100%);
  }
}

.marquee {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  color: var(--marque-text-color);
}

.marquee span {
  display: inline-block;
  padding-left: 50%;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Arial';
  text-transform: uppercase;
  animation: marquee 20s linear infinite;
}