a {
  outline: none;
}

.AccountSettingHeader-container ul {
  margin: 0;
}

.AccountSettingHeader-container ul li {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.AccountSettingHeader-container ul li a {
  color: var(--black-color);
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  transition: color 0.5s;
}

.AccountSettingHeader-container ul li .underline {
  height: 2px;
  background-color: transparent;
  width: 0%;
  transition: width 0.2s, background-color 0.5s;
  margin: 0 auto;
}

.AccountSettingHeader-container ul li.active-link .underline {
  width: 100%;
  background: var(--review-rating-active-btn);
}

.AccountSettingHeader-container {
  padding: 0px 5px;
}