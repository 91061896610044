.right_conatct_social_icon {
  background: linear-gradient(254deg, var(--sura-text-color) 7.39%, var(--crrote-color) 88.94%);
}
.contact_inner {
  background-color: var(--white-btn-bg-color);
  position: relative;
  box-shadow: 20px 22px 44px var(--search-input-border-color)c;
  border-radius: 25px;
}
.contact_field {
  padding: 20px 340px 90px 65px;
}
.right_conatct_social_icon {
  height: 100%;
}
.small-err{
  color: red!important;
}
.contact_field h3 {
  color: var(--black-color);
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px
}
.contact_field p {
  color: var(--black-color);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 25px;
  margin-top: 10px;
}
.contact_field .form-control {
  border-radius: 0px;
  border: none;
  color: var(--finance-checkupload);
  border-bottom: 1px solid var(--search-input-border-color);
}
.contact_field .form-control,
select::placeholder {
  letter-spacing: 1px;
  color: var(--contact_field-text-color);
}
.contact_field .form-control:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid var(--sura-text-color);
  color:var(--white-color)
}
.contact_field .form-control::placeholder {
  letter-spacing: 1px;
  color: var(--contact_field-text-color) !important;
}

.contact_info_sec {
  position: absolute;
  top: 18%;
  height: 340px;
  width: 40%;
  border-radius: 25px 0 0 25px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  right: 0;
}
.contact_info_sec h4 {
  letter-spacing: 1px;
  padding-bottom: 15px;
}
button.contact_form_submit {
  background: linear-gradient(254deg, var(--sura-text-color) 7.39%, var(--crrote-color) 88.94%);
  border: none;
  color: var(--white-text-color);
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;
}
.socil_item_inner li {
  list-style: none;
}
.socil_item_inner li a {
  color: var(--white-text-color);
  margin: 0px 15px;
  font-size: 14px;
}
.socil_item_inner {
  padding-bottom: 10px;
}

@media (max-width:922px) {
  .contact_field {
    padding: 4px;
  }
  .contact_info_sec {
    background-color: unset;
    right: 1px;
    padding: 40px;
    border-radius: 25px 0 0 25px;
    background-image: unset !important;
  }
  .contact_field h3 {
    margin-top: 10px;
    color: var(--black-color);
    font-size: 25px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}
@media (max-width:541px) {
  .right_conatct_social_icon {
    background: unset;
  }
}