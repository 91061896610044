.search-display-desc-btn {
    display: flex;
    justify-content: space-evenly;
    column-gap: 10px;
}

.search-display-desc-btn-Service,
.search-display-desc-btn-Review {
    color: var(--black-color);
    padding: 2px;
    border: 1px solid var(--category-hover-color);
    font-size: 16px;
    letter-spacing: 0.8px;
    padding: 5px 9px;
    border: 1px solid var(--category-hover-color);
    margin-right: 6px;
    border-radius: 15px;
    display: flex;
    align-items: center;
}

.search-display-desc-btn-Service:hover,
.search-display-desc-btn-Review:hover {
    /* border-bottom: 3px solid var(--sura-text-color) !important; */
    text-decoration: none !important;
    color: var(--black-color);
    padding: 2px;
    border: 1px solid var(--category-hover-color);
    font-size: 16px;
    letter-spacing: 0.8px;
    padding: 5px 9px;
    border: 1px solid var(--category-hover-color);
    margin-right: 6px;
    border-radius: 15px;
}


.abouts
 {
    border-bottom: 1px solid var(--graph-border-bolor);
    padding: 10px;
}

.view-all-btn,
.service_desc_address,
.search-display-desc-container3-text,
.search-display-desc-container4-desc-contain {
    display: flex;
    margin: 1rem 0rem 0rem 0rem;
}

.search-display-desc-container {
    max-height: 120px;
    overflow-x: hidden;
    max-width: 100%;
    overflow-y: hidden;
}

.map-div-dis-serv-desc hr {
    width: 96% !important;
}

.search-display-desc-icon-row {
    display: flex;
    font-size: 18px;
    justify-content: space-between;
}

.search-display-desc-icon-row-text,
.search-display-desc-icon-share-text {
    font-size: 15px;
    color: black;
}

.search-display-desc-icon-share {
    color: var(--share-icon-color);
    font-size: 17px;
}

.fb-icon,
.whatsapp-icon,
.line-icon,
.twitter-icon {
    font-size: 25px;
}

.fb-icon {
    color: var(--blue-color);
}

.whatsapp-icon {
    color: var(--green-color);
}

.line-icon {
    color: var(--green-color);
}

.twitter-icon {
    color: var(--twitter-icon-color);
}

.search-display-desc-icon-like {
    cursor: pointer;
    color: var(--red-color);
    font-size: 17px;
}

.days {
    padding: 0px 0px 0px 10px;
    text-transform: capitalize;
    font-size: 15px;
}


.search-display-desc-container3-icons-share {
    font-size: 20px;
    color: var(--sura-text-color);
}

.custom-dropdown-menu-share {
    background-color: var(--white-btn-bg-color);
    border: 1px solid var(--placeholder);
    border-radius: 4px;
    width: auto;
}


.search-display-desc-container3 {
    border: 1px solid #BFC9CA;
    padding: 0px 10px 10px 10px;
    box-shadow: 0 0 5px var(--dark-gray-color);
    word-wrap: break-word;
    height: 15rem;
}

.search-display-desc-btn-Service.active,
.search-display-desc-btn-Review.active,
.search-display-desc-btn-Overview.active {
    color: white;
    font-size: 16px;
    letter-spacing: 0.8px;
    padding: 5px 9px;
    border: 1px solid var(--hotpink-border-color);
    margin-right: 6px;
    border-radius: 15px;
    background-color: hotpink;
    display: flex;
    align-items: center;
}

.search-display-desc-container3-para1 {
    word-wrap: break-word;
    height: 100px;
    overflow: auto;
    font-size: 13.5px;
    text-align: justify;
}

.search-display-desc-container3-para1 {
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.search-display-desc-container4-icons-star {
    color: var(--star-color)
}

.search-display-desc-container4-progress1 {
    display: flex;
    background-color: var(--container4-progress1);
    border-radius: 20px;
    position: relative;
    margin: -1px 0px 13px 0px;
    height: 16px;
    opacity: 1;
}

.search-display-desc-container4-progress1-done,
.search-display-desc-container4-progress2-done,
.search-display-desc-container4-progress4-done,
.search-display-desc-container4-progress3-done {
    background: linear-gradient(to left, #58AD1E, #AFCA0D, #FEE703, #FABC29, #FF1400);
    border-radius: 5px;
    border-radius: 20px;
    color: var(--white-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    font-size: 13px;
    font-weight: bold;
    transition: 1s ease 0.3s;
}

.search-display-desc-container4-progress {
    display: flex;
    justify-content: space-between;
}

.review {
    font-size: 13.5px;
    height: 100px;
    overflow-x: auto;
}


.search-display-desc-container4-desc-container {
    border: 1px solid var(--sura-text-color);
    padding: 10px;
    border-radius: 5px;
}

.search-display-venue-name {
    color: var(--sura-text-color);
    text-transform: capitalize;
}

.search-display-review {
    padding: 0px 10px 10px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    font-size: 14px;
}

@media screen and (max-width:460px) {
    .deal-header__title {
        font-size: 16px;
    }

    .search-display-desc-btn-Overview,
    .search-display-desc-btn-Overview:hover,
    .search-display-desc-btn-Service:hover,
    .search-display-desc-btn-Service,
    .search-display-desc-btn-Review,
    .search-display-desc-btn-Review:hover {
        color: black;
        padding: 2px;
        border: 1px solid var(--category-hover-color);
        font-size: 16px;
        letter-spacing: 0.8px;
        padding: 5px 9px;
        border: 1px solid var(--category-hover-color);
        margin-right: 6px;
        border-radius: 15px;

    }
}


.best-deals {
    padding: 10px 10px 20px 10px;
}

.view-all-heart-icon {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
}

.location-icon {
    color: black !important;
}

.best-deals__deal-item {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "header header "
        "picture picture"
        "description description"
        "benefits price";

        outline: 1px solid gainsboro;
    box-sizing: border-box;
    padding: 10px 10px 0px 10px;
    background: #fff;
    border-radius: 5px;

}

.deal-item__deal-header {
    grid-area: header;
}

.deal-header__title {
    font-size: 16px;
}

.deal-header__title:hover{
    color: var(--sura-text-color);
    cursor: pointer;
}

.view-all-star {
    font-size: 0px;
}

.view-all-star .anticon {
    font-size: 14px !important;
}


.view-all-service-container {
    background:var(--white-btn-bg-color);
    padding: 20px;
  outline: 1px solid gainsboro;
  border-radius: 5px;

}

.view-all-service-container-day {
    background: #fff;
    padding: 10px 10px 6px 10px;
    margin-right: -8px;
    outline: 1px solid gainsboro;
    border-radius: 5px;
}

.search-display-desc-container3-para1::-webkit-scrollbar,
.review::-webkit-scrollbar {
    width: 5px;
}

.search-display-desc-container3-para1::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
    background-color: var(--sura-text-color);
    border-radius: 1em;
}

.search-display-desc-container3-para1::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
    background-color: var(--sura-text-color);
}

.search-display-desc-container3-para1::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
    background-color: var(--scrollbar-color);
}

.search-display-desc-description_eng,
.search-display-desc-description_thai {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.ant-rate-star:not(:last-child) {
    margin-inline-end: 0px !important;
    font-size: 16px !important;
}

.header-cart .anticon {
    font-size: 25px !important;
}

.main-section {
    flex: 1; /* Flex item ko expand karne ke liye */
    padding: 10px 0px 28px 0px;
    background: #FFFFFF;
    border-radius: 5px;
    outline: 1px solid gainsboro;
}

.average-rating {
    float: left;
    text-align: center;
    width: 18%;
    margin-top: 22px;
    text-transform: capitalize;
}

.ambience-rating {
    display: inline-block;
    width: 17%;
}

.ambience-rating div {
    margin-bottom: 5px;
}

.average-rating p {
    margin: 0px;
    font-size: 20px;
}

.loder-ratimg {
    display: inline-block;
    width: 42%;
}

.start-parts {
    float: right;
    width: 20%;
    text-align: center;
}

.start-parts div {
    margin: 0px 0px 4.6px 0px;
}

.start-parts .rating-percentage {
    padding: 5px;
    text-align: center;
    background: var(--progress-bar-fill-color);
    -webkit-background-clip: text;
    color: transparent;
}

.reviews h1 {
    margin: 10px 0px 20px 30px;
}

.user-img img {
    height: 80px;
    width: 80px;
    border: 1px solid var(--sura-text-color);
    border-radius: 50%;
}

.user-img-part {
    width: 30%;
    float: left;
}

.search-display-desc-outer-div .slick-prev,
.search-display-desc-outer-div .slick-next {
    opacity: 1;
    color: white !important;
    border-radius: 50%;
    padding: 11px;
    font-size: 28px !important;
    outline: 2px solid white !important;
}

.search-display-desc-outer-div .slick-prev::after {
    margin: 3px 5px;
}

.search-display-desc-outer-div .slick-next::after {
    margin: 3px 0px;
}

.search-display-desc-outer-div .slick-prev:hover,
.search-display-desc-outer-div .slick-next:hover {
    opacity: 1;
    color: white !important;
    border-radius: 50%;
    padding: 11px;
    font-size: 28px !important;
    outline: 2px solid white;
}

.search-display-desc-outer-div .slick-prev:before,
.search-display-desc-outer-div .slick-next:before {
    font-size: unset;
    line-height: 1;
    opacity: .0 !important;
    color: white;
    -webkit-font-smoothing: antialiased;
}

.user-img {
    width: 48%;
    float: left;
    text-align: center;
}

.user-text {
    text-transform: capitalize;
    width: 48%;
    float: left;
    overflow: hidden;
    white-space: nowrap;
}

.user-text h4,
.user-text p {
    margin: 0px 0px 5px 0px;
    white-space: nowrap;
    overflow-x: hidden;
}

.user-text p {
    font-size: 14px;
    font-weight: bold;
    color: var(--container4-progress1);
}

.user-text span {
    color: var(--container4-progress1);
}

.comment {
    width: 68%;
    float: right;
}

.progress {
    background-color: var(--container4-progress1);
    border-radius: 20px;
    position: relative;
    margin: 15px 0;
    height: 30px;
    width: 300px;
}

@media screen and (max-width:678px) {
    .user-text {
        text-transform: capitalize;
        width: 100%;
        float: left;
    }

    .comment {
        width: 50%;
        float: right;
    }
}



.serach-display-desc-aboutus {
    padding: 0px 10px 0px 10px;
    text-align: justify;
}

@media (max-width:364px) {
    .search-display-desc-role {
        margin-top: 130px !important;
    }
}

.search-display-service-desc-btn-div {
    display: flex !important;
    justify-content: space-between !important;
}

.view-all-heart-icon>span>div>div>div>i {
    font-size: 15px;
}

.view-all-heart-icon>span>div>div>div {
    display: flex;
    justify-content: top;
    justify-content: space-around;
}

.display-service-image-secion-img {
    max-width: 100%;
    margin-bottom: 10px;
}

.search-display-best-deals-span>.hl05eU>.yRaY8j {
    margin-left: 0px !important;
}


.search-display-desc-btn-Service.active a:hover {
    color: white !important;
    border: none;
    text-decoration: none;
}

.search-display-desc-btn-Review a:hover {
    color: black !important;
    border: none;
    text-decoration: none !important;
    background-color: inherit !important;
}

.search-display-desc-btn-Review>a:hover {
    color: black !important;
}

.search-desc-active-btn {
    background-color: var(--category-hover-color);
    color: white;
}

.search-desc-active-btn>a {
    background-color:var(--category-hover-color);
    color: white;
    text-decoration: none !important;
}

.search-desc-active-btn:hover {
    background-color: var(--category-hover-color) !important;
    color: white !important;
    text-decoration: none !important;
}

.search-desc-active-btn>a:hover {
    background-color: var(--category-hover-color) !important;
    color: white !important;
    text-decoration: none !important;
}

.search-desc-de-active-btn:hover {
    color: black !important;
    background-color: transparent !important;
    text-decoration: none !important;

}

.search-desc-de-active-btn>a {
    color: black !important;
    background-color: transparent !important;
    text-decoration: none !important;

}

.ant-btn:not(:disabled):focus-visible {
    outline: none !important;
    outline-offset: 0px !important;
    transition: none !important;
}

.search-display-des-outer-div>div>.view-all-service-container>div>div>.service_desc_address {
    margin: 0px !important;
}

.search-display-desc-image-div>div>div>.main-section>.hedding-title>.search-display-review {
    padding: 0px 10px 10px 10px !important;

}

@media (max-width:991px) {
    .view-all-service-container-day {
        margin-top: 20px;
        margin-left: calc(-.5* var(--bs-gutter-x));
    }

    .map-div-dis-serv-desc {
        margin-top: 20px;
    }

    .map-div-dis-serv-desc .map-div-dis-serv-row div {
        width: 100%;
        height: 210px;
    }

    .main-section {
        padding: 10px 0px 0px 0px;
    }
}

@media (max-width:768px) {
    .start-parts {
        width: 30%;
    }

    .ambience-rating {
        display: inline-block;
        width: 23%;
    }

    .loder-ratimg {
        display: inline-block;
        width: 29%;
    }

    .start-parts div {
        margin: 0px 0px 4px 0px;
    }

    .best-deals__deal-item {
        padding: 10px 10px 0px 10px !important;
    }

    .average-rating {
        margin-top: 0px;
    }
}

.read-more-service-desc {
    font-size: 15px;
    color: var(--sura-text-color);
    cursor: pointer;
    text-decoration: underline;
}

.desc-heading-outer {
    max-height: 47vh;
    overflow: auto;
}


.customModalDesc {
    width: 769px !important;
    max-width: 769px !important;
    margin: auto !important;
    top: 50% !important;
    border: none !important;
    transform: translateY(-50%) !important;


}

.desc-heading-inner {
    padding: 0px 10px 0px 10px;
}

.desc-message {
    text-align: justify;
}

.desc-heading {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

@media (max-width:767px) {
    .customModalDesc {
        width: 100% !important;
        max-width: 100% !important;
    }

    .desc-heading-inner {
        padding: 0px 0px 0px 0px;
    }

}

@media (min-width: 1125px) {

    .display-service-outer-div,
    .search-display-des-outer-div {
        margin-top: 150px;
    }
}

@media (max-width:1124px) and (min-width:991px) {
    .search-display-des-outer-div {
        margin-top: 150px;
    }
}

@media (max-width:991px) and (min-width:421px) {
    .search-display-des-outer-div {
        margin-top: 95px !important;
    }
}

@media (max-width:460px) {

    .average-rating {
        width: 100%;
    }

    .start-parts {
        width: 42%;
    }

    .ambience-rating {
        width: 8%;
        text-align: center;
        margin-left: 8px;
    }

    .loder-ratimg {
        position: relative;
        left: 21%;
        width: 30%;
    }

    .start-parts .ant-rate {
        display: none;
    }

}

.start-parts .single-star,
.start-parts .single-star-unfill {
    display: none;
}

@media screen and (max-width:335px) {
    .user-img img {
        width: 65px;
        height: 65px;
    }

    .ambience-rating {
        width: 13%;
        font-size: 15.8px;
        margin-left: 10px;
    }

    .start-parts {
        width: 35%;
    }

    .loder-ratimg {
        width: 29%;
    }

    .display-service-price-outer {
        display: unset !important;
    }


    .start-parts .single-star-unfill {
        display: inline;
        color: #d8d8d8;
    }

    .share-button {
        font-size: 16px;
    }
}

@media (max-width:385px) {
    .service_desc_address>p {
        font-size: 13px;

    }

    .service_desc_address>.location-icon>span {
        font-size: 14px;

    }
}

@media (max-width:460px) {
    .start-parts .single-star {
        display: inline !important;
        color: gold;
    }
}

/* ------------------------------------------------ new design service section css ------------------------ - */