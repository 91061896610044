.revenue-div {
    height: 100px;
    width: 100px;
    background-color: var(--revenue-bg-color);
    margin-left: -11px;
}
.revenue-div i {
    font-size: 40px;
    justify-content: center;
    display: flex;
    color:var(--white-color);
    padding: 20px;
}
.visitors-div {
    height: 100px;
    width: 100px;
    background-color: var(--visitors-bg-color);
    margin-left: -11px;
}

.dashbord-right {
    right: 2%;
    top: 34px;
    width: 30%;
    height: auto;
}

.visitors-div i {
    font-size: 40px;
    justify-content: center;
    display: flex;
    padding: 28px;
    color: var(--white-color);
}
.shares-div {
    height: 100px;
    width: 100px;
    background-color: var(--shares-bg-color);
    margin-left: -11px;
}
.shares-div img {
    padding: 24px 24px;
}
.revenue-div_text {
    padding: 12px;
}
.visitors-div_text {
    padding: 12px;
}
.shares-div_text {
    padding: 12px;
}
.revenue-div_text h4 {
    font-weight: 900;
}
.revenue-div_text h4 .hl05eU .Nx9bqj{
    font-size: 1.5rem!important;

}
.visitors-div_text h4 {
    font-weight: 900;
}
.shares-div_text h4 {
    font-weight: 900;
}
.vendor_graph {
    border: 1px solid var(--graph-border-bolor);
    background-color: var(--white-background-color);
    max-width: 100vw;
}

.vendor_graph_head {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dashboard_table_row_divider {
    border-bottom: 1px solid var(--dashboard-filter-inner-div-border);
}
.dashboard_user_image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: 0px;
}
.vendor-dashboard-user {
    width: 250px;
}
.vendor-dashboard-rating {
    width: 150px;
}
.dashboard_username {
    display: flex;
}
@media screen and (max-width:768px) {
.dashboard_username {
        display: block;
    }
}
@media screen and (max-width:632px) {
    .vendor_graph_service_table {
        overflow-x: auto;
    }
    .dashboard_ratings {
        display: flex;
    }
}

@media screen and (max-width:366px) {
    .revenue-div {
        height: 70px;
        width: 70px;
    }
    .revenue-div i {
        padding: 4px;
    }
    .visitors-div {
        height: 70px;
        width: 70px;
    }
    .visitors-div i {
        padding: 16px;
    }
    .shares-div {
        height: 70px;
        width: 70px;
    }
    .shares-div img {
        padding: 10px;
    }
    .revenue-div_text p {
        font-size: 12px;
    }
    .visitors-div_text p {
        font-size: 12px;
    }
    .shares-div_text p {
        font-size: 12px;
    }
    .revenue-div_text h4 {
        font-size: 18px;
    }
    .visitors-div_text h4 {
        font-size: 18px;
    }
    .shares-div_text h4 {
        font-size: 18px;
    }
}
@media (max-width:576px) {
    .vendor-dashboard-table-heading {
        font-size: 14px;
    }
}
@media (max-width:920px) and (min-width:768px) {
.vendor-dashboard-table-heading {
        font-size: 14px;
    }
}
.braches-text,
.braches-text :hover,
.braches-text :active {
    font-weight: bold !important;
}
.box-shadow-all {
    background-color: var(--white-background-color);
    border-radius: 10px;
    background: var(--inner-background-db);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow:var(--box-shado-all-dashboard);
}
.revenue-div {
    border-radius: 10px;
}
.visitors-div {
    border-radius: 10px;
}
.shares-div {
    border-radius: 10px;
}
.vendor-dashboardd-viewAll {
    color: var(--sura-text-color);
    cursor: pointer;
    font-weight: 400;
}
.dashboard-inner-table-heading {
    font-size: 16px;
}
.vendor-dashboard-table-heading th {
    font-size: 15px;
}
.table tbody tr td {
    font-size: 15px;
    height: 46px;
}
.mdl-menu__container {
    position: absolute;
    z-index: 1000;
    display: none;
}
.mdl-menu__container.is-visible {
    display: block;
}
.mdl-button--icon .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px;
}
.mdl-button--icon {
    border: none;
}
.mdl-button {
    background: 0 0;
    position: relative;
    height: 36px;
    padding: 0 16px;
}
.mdl-button--icon:hover,
.mdl-button--icon:active {
    border-radius: 50%;
    font-size: 24px;
    text-align: center;
    width:35px;
}
.mdl-button:hover {
    background-color:var(--mdl-btn-color);
}
.material-icons {
    font-size: 16px;
    display: inline-block;
    line-height: 1;
}
.is-btn-active {
    background-color:var(--mdl-btn-color);
    border-radius: 50%;
    width:35px;
}
.is-visible {
    padding: 8px 4px;
    background-color: var(--white-background-color);
    box-shadow:var(--box-shado-all-dashboard-is-visible);
}
.company-details-inner,
.contact-person-details-inner {
    min-height: 480px;
}
.canvasjs-chart-credit {
    display: none;
}
.vendor-dashboard-filter-span {
    padding: 10px;
}
.dashboard-filter-inner-div {
    cursor: pointer;
    padding: 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color:var(--dashboard-filter-inner-div-border);
}
.last-list{
    border: none;
}
.dashboard-filter-inner-div:hover {
    background-color:var(--dashboard-filter-inner-div-bg);
    color: var(--sura-text-color);
}
.active-filter {
    background-color:var(--dashboard-filter-inner-div-bg) !important;
    color: var(--sura-text-color) !important;
}
@media (max-width:768px) {
    .dashboard_username{
        text-align: center;
    }
    .company-details-inner,
.contact-person-details-inner {
    min-height: auto;
}
}
.dashbord-upcoming-booking>.box-content{
    overflow: auto;
}
.vendor-dashbord-price-td>.hl05eU{
   display: flex;
   justify-content: flex-end;
}
@media (max-width:366px) {
.revenue-div_text,.visitors-div_text ,.shares-div_text {
    padding: 2px;
}
.revenue-div_text h4 .hl05eU .Nx9bqj{

    font-size: 18px!important;
}
}

.revenue-div_text{
    white-space: nowrap;
}
@media (max-width:991px) {
    .company-details-inner, .contact-person-details-inner {
        min-height: auto;
    }
}
@media (max-width:991px)  and (min-width:769px){
    .revenue-div_text{
        padding: 10px 0px 0px 0px;
    }
    .revenue-div_text>p{
white-space: nowrap;
    }
    .revenue-div_text h4 .hl05eU .Nx9bqj {
        white-space: nowrap;
        font-size: 1.2rem !important;
    }
}