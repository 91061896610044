.add-to-cart-outer-most-container {
  text-transform: capitalize;
}

.display-end-class {
  display: flex;
  justify-content: flex-end;
}

.hl05eU {
  display: "flex"
}

sub {
  color: var(--red-color);
}

.add-to-cart-currency>.hl05eU>.yRaY8j {
  margin-left: 0px;

}

.gradient-custom .card {
  margin: auto;
  width: 100%;
  border: transparent
}

.add-to-cart-modal .confirm,
.add-to-cart-modal .confirm:hover {
  background-color: var(--sura-text-color) !important;
  color: var(--white-text-color) !important;
}

.ant-btn-primary {
  background-color: var(--sura-text-color) !important;
  color: var(--white-text-color);
}

.cart-heading {
  font-size: 18px;
  text-align: center;
}

.add-font-size {
  font-size: 14px;
  margin: 0;
}

.view-all-function-class {
  cursor: pointer;
}

.view-all-function-class:hover,
.add-to-cart-org-name:hover {
  color: var(--sura-text-color);
}

.add-font-size1 {
  font-size: 10px;

}

.add-font-size-price {
  font-size: 10px;
  margin: 0;
}

.gradient-custom .card-body {
  padding: 23px;
}

.ant-picker-ok button {
  background: var(--sura-text-color);
  color: var(--white-text-color);
  font-weight: bold;
}

@media(max-width:767px) {
  .card {
    margin: 3vh auto
  }
}

.select-all-div {
  padding-left: 8px;
}

.selectAll-lable {
  margin-left: 18px;
}

.gradient-custom .card {
  border-bottom-left-radius: 1rem;
}

@media(max-width:767px) {
  .cart {
    padding: 15px;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem
  }
}

.summary {
  background-color: var(--dashboard-filter-inner-div-bg);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: var(--mdl-btn-color)
}

.summary .col-2 {
  padding: 0
}

.summary .col-10 {
  padding: 0
}

.bg-image-cart img {
  height: 100px;
  width: 140px;
  border-radius: 5px;
}

.checkout-btn {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--hotpink-border-color);
  border-style: none;
  color: var(--white-text-color);
  ;
}

.checkout-btn-active {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background: var(--sura-text-color);
  border-style: none;
  color: var(--white-text-color);
}

.ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled,
.ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled+.ant-picker-time-panel-cell {
  display: none;
}

.ant-modal-footer button {
  font-weight: bold;
}

.ant-modal-footer .ant-btn-primary {
  background: var(--sura-text-color);
  color: var(--white-text-color);
  font-weight: bold;
}

.modal-backdrop.fade {
  opacity: 1 !important;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: var(--black-color);
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.user-inner-content-scrollar-add-to-cart {
  height: calc(100vh - 300px);
}

.add-to-cart-outer-row-map-mobile {
  display: none;
}

@media (max-width:992px) and (min-width:768px) {
  .user-inner-content-scrollar-add-to-cart {
    height: auto;
  }
}

@media(max-width:767px) {
  .summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem
  }

  .user-add-to-cart-inner-div>.gradient-custom>.justify-content-center>.col-md-8>.card>.user-inner-content-scrollar-add-to-cart {
    height: 100% !important;
  }

  .user-add-to-cart-inner-div>.gradient-custom>.justify-content-center>.col-md-8>.card>.user-inner-content-scrollar {
    max-height: 100% !important;
  }
}

@media (max-width:574px) {
  .add-to-cart-outer-row-map {
    display: none;
  }

  .add-to-cart-outer-row-map-mobile {
    display: block;
  }

  .select-all-div {
    padding-left: 5px;
  }

  .card .card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  }

  .card-header>.product {
    padding: 0px 5px 0px 0px;
  }

  .add-to-cart-action-div {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
  }

  .add-cart-img-div-mobile {
    display: flex;
    justify-content: center;
  }

  .add-to-cart-shop-img-mobile {
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }

  .add-to-cart-organisation-and-time-div {
    display: flex;
    justify-content: center;
  }

  .add-to-cart-price-div-mobile {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
}

@media (max-width:492px) {
  .user-add-to-cart-outer-container>.user-add-to-cart-inner-div>.gradient-custom .card {
    padding: 0vh 0vh;
    border-bottom-left-radius: 1rem;
  }

  .user-add-to-cart-common-box>.user-common-box-inner {
    padding: 0rem;
  }

  .user-add-to-cart-inner-div>.gradient-custom>.row>.col-md-4>.card>.card-body>.list-group>.list-group-item>span {
    font-size: 15px;
  }

  .user-add-to-cart-inner-div>.gradient-custom>.row>.col-md-4>.card>.card-body>.list-group>.list-group-item>span>.hl05eU>.Nx9bqj {
    font-size: 14px !important;
  }

  .user-add-to-cart-inner-div>.gradient-custom>.row>.col-md-4>.card>.card-body>.checkout-btn {
    padding: 5px;
  }
}

.modal-text>.bi-clock-fill {
  font-size: 10px;
}

.add-to-cart-duration-span {
  font-size: 13px;
  font-weight: 0;
}

.add-to-cart-product-hr-tag {
  width: -webkit-fill-available !important;
}

.add-to-cart-price-div-mobile>.add-font-size>.hl05eU {
  display: flex;
}

.add-to-cart-price-div-mobile>.hl05eU {
  display: flex !important;
}

.add-font-size>.hl05eU>.currency-display-sub-price-second {
  margin-top: 0px !important;
}

@media (max-width:1024px) {
  .bg-image-cart img {
    width: 100%;
  }
}

.add-to-cart-branch-select-tag>div>.ant-select-selector {
  max-width: 100% !important;
}

.add-to-cart-branch-select-tag>.ant-select {
  width: 164px;
}

.add-to-cart-branch-select-tag>div>.ant-select-selector>.ant-select-selection-item,
.add-to-cart-branch-select-tag>div>.ant-select-selector>.ant-select-selection-search {
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}