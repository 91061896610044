.notification-panel {
  position: absolute;
  top: 60px;
  right: 10px;
  width: 440px;
  background-color: var(--white-btn-bg-color);
  border-radius: 8px;
  box-shadow: 0 2px 10px var(--payout-bottum-border);
  z-index: 1000;
}

.inside-content-div {
  max-height: 650px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--message-icon-color);
  padding: 10px;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--sura-text-color);
  color: var(--white-text-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.notification-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.close-icon {
  cursor: pointer;
  font-size: 16px;
}

.notification-section {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.notification-section-header {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: var(--ant-pagination-item-active-bg);
  top: 50px;
  background-color:  var(--white-btn-bg-color);
  padding: 10px;
  z-index: 1;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 4px;
  background-color:var(--notification-bg-color);
  margin: 0 10px 10px 10px;
  box-shadow: 0 1px 3px var(--cashback-container-box-shadow);
}

.notification-message {
  display: flex;
  flex-grow: 1;
  margin-right: 10px;
  font-size: 13px;
}
.notification-message>.message-icon{
  margin-top: 1px;
}
.message-icon {
  color:var(--message-icon-color);
  margin-right: 8px;
}

.notification-time {
  font-size: 12px;
  color: var(--contact_field-text-color);
  white-space: nowrap;
}

.no-notifications {
  text-align: center;
  color: var(--contact_field-text-color);
  padding: 10px;
  min-height: 65vh;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.no-notifications h5 {
  margin-top: 65% !important;
  display: flex;
  justify-content: center;
}

.notification-container {
  text-transform: capitalize;
}

.vendor-notification-sup {
  font-weight: bold;
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 35%;
  padding: 0.2em 0.4em;
  transform: translate(-60%, -50%);
  background-color:  var(--white-btn-bg-color);
  color: var(--bg-color);
  border-radius: 50%;
  background: var(--bg-color);
color: var(--white-text-color);
  margin-top: -2px;
  line-height: 1;
  white-space: nowrap;

}

.navbar-sup-notification {
  font-weight: bold;
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 35%;
  padding: 0.2em 0.4em; 
  transform: translate(-60%, -50%);
  background-color:  var(--white-btn-bg-color);
  color: var(--bg-color);
  border-radius: 50%;
  background: var(--bg-color);
  color:  var(--white-text-color);
  margin-top: -2px;
  line-height: 1;
  white-space: nowrap; 

}


@media screen and (max-width:768px) {
  .notification-icon {
    margin: 15px 0px 0px 0px !important;
  }
}

@media screen and (max-width:269px) {
  .navbar-sup-notification {
    top: 65%;
  }

}

@media (max-width:460px) {
  .notification-panel{
    right: 1%;
    width: 98%;
  } 
}

.notification-item>.notification-message>.text-truncate {
  white-space: normal !important;
}

.unread-message{
  font-weight: 600;
}