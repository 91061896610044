:root {
  --marque-text-color: black;
  --body-bg-color: whitesmoke;
  --sura-text-color: #EE0A89;
  --secondary-color: #6c757d;
  --bg-color: linear-gradient(180deg, #EE0A89 0%, #FD6266 100%);
  --button-bg-color: linear-gradient(180deg, #EE0A89 0%, #FD6266 100%);
  --crrote-color: #FD6266;
  --white-text-color: white;
  --white-color: white;
  --white-btn-bg-color: white;
  --white-btn-subscribe-bg-color: white;
  --button-text-color: white;
  --scrollbar-color: #f0f0f0;
  --hotpink-border-color: hotpink;
  --row-box-color: #ea247f1a;
  --black-color: black;
  --bg-black-color: black;
  --card-btn-color: #FF0000;
  --placeholder-color: #c2bbbb;
  --team-card-bg-color: #ffffff;
  --team-card-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  --team-card-img-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  --team-card-bg-b-purple: linear-gradient(45deg, #a52dd8, #e29bf1);
  --team-add-btn: #ea247f1a;
  --dark-gray-color: darkgrey;
  --vendor-outer-container: rgba(247, 247, 249, 1);
  --search-icon-color: #555;
  --progress-bar-color: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --search-input-color: #f8f8f8;
  --search-input-border-color: #ccc;
  --search-btn: #D9D9D9;
  --container4-progress1: #d8d8d8;
  --progress-bar-fill-color: linear-gradient(to left, #F2709C, #FF9472);
  --progress-bar-box-shadow-color: #F2709C;
  --star-color: #F1C40F;
  --review-rating-active-btn: rgba(238, 10, 137, 1);
  --review-list-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --circle-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --cashback-container-box-shadow: rgba(0, 0, 0, 0.1);
  --lang-border-color: #ECF0F1;
  --phone-err: rgb(241, 107, 107);
  --red-color: red;
  --payout-summary-daily-transaction-inner-box-shadow: rgba(0, 0, 0, 0.05);
  --payout-box-shadow: rgb(209, 213, 219);
  --payout-bottum-border: rgba(0, 0, 0, 0.2);
  --warning-container-bg-color: #F0F0F0;
  --filter-drop-shadow: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  --vendor-header-box-shadow: rgba(0, 0, 0, 0.06);
  --finance-checkupload: #495057;
  --form-select: #cbd5e1;
  --revenue-bg-color: rgba(212, 82, 52, 1);
  --visitors-bg-color: rgba(137, 194, 46, 1);
  --shares-bg-color: rgba(101, 175, 159, 1);
  --graph-border-bolor: rgba(217, 217, 217, 1);
  --share-icon-color: #7abe8e;
  --mdl-btn-color: rgba(158, 158, 158, .2);
  --is-visible-box-shadow1: rgba(0, 0, 0, .2);
  --is-visible-box-shadow2: rgba(0, 0, 0, .12);
  --is-visible-box-shadow3: rgba(0, 0, 0, .14);
  --dashboard-filter-inner-div-bg: #ddd;
  --search-filter-input-feild-bg: #F8F8F8;
  --inner-container-db: #F4F6F6;
  --inner-background-db: #fff;
  --dashboard-filter-inner-div-bg: #eee;
  --dashboard-filter-inner-div-border: #ddd;
  --warning-container-bg: #D9D9D9;
  --white-smoke-focus-feild: whitesmoke;
  --button-hour-blue: #2A4556;
  --custom-link: #FD6266;
  --step-icon-bg: #e6e6e6;
  --times-icon: #b6b6b6;
  --branches-btn-bg: linear-gradient(180deg, #ee0a89 0%, #fd6266 100%);
  --green-color: green;
  --blue-color: blue;
  --cancelled: #B31312;
  --completed: #3A974C;
  --expired: #EB5757;
  --orange-color: orange;
  --expired-bg: rgba(235, 87, 87, 0.1);
  --ant-modal-content-box-shadow1: rgba(0, 0, 0, 0.19);
  --ant-modal-content-box-shadow2: rgba(0, 0, 0, 0.23);
  --bg-img-bg: #ffc0cb94;
  --sign-in-box-shadow: rgba(253, 98, 102, 0.80);
  --all-service-container-box-shadow1: rgba(50, 71, 92, 0.06);
  --all-service-container-box-shadow2: rgba(50, 71, 92, 0.04);
  --all-service-container-box-shadow3: rgba(50, 71, 92, 0.02);
  --referral-code-bg: #f2f2f2;
  --step-icon-bg: #e6e6e6;
  --hair-slider-outerContainer-bg: rgba(247, 109, 109, 0.1);
  --menu-sidebar-header-section-border-bottum: #80808040;
  --navbar-status-section-bg: linear-gradient(180deg, #e51b8b 0%, #e12025 100%);
  --card-text-color: #D7BDCA;
  --book-border-color: #CACFD2;
  --header-box-shadow: rgba(99, 99, 99, 0.2);
  --header-box-shadow-menu: pink;
  --card-webkit-box-shadow: rgba(68, 102, 242, .05);
  --heasing-icon-shadow: #ee028985;
  --healthTreatment_ShowListDiscountPrice: rgba(255, 112, 0, 1);
  --box-shado-all-dashboard: drop-shadow(0px 1px 10px 1px rgba(0, 0, 0, 0.05));
  --box-shado-all-dashboard-is-visible: drop-shadow(0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12));
  --vendor-profile-right-part-shadow: drop-shadow(rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px);
  --bread-com: #5c5c5c;
  --cancel_deletion_timerbox-bg: rgba(248, 248, 248, 1);
  --ant-pagination-item-active-bg: #007bff;
  --btn-status-bg: #FDF4EB;
  --reviewShop-rating-modal-box-shadow: rgba(100, 100, 111, 0.2);
  --cashback-card-bg: linear-gradient(230deg, #e42f8f 60%, rgba(239, 17, 134, 1) 60%);
  --service-display-desc-container3-btn-cart-icon-bg: rgba(239, 239, 239, 1);
  --bd-grid-card-bg-color: #FFE8DF;
  --navbar-box-shadow: rgba(149, 157, 165, 0.2);
  --search-box-section-bg: #eaeaeb;
  --placeholder: #d9d9d9;
  --bussinessInfo-text-color: #4FBEA3;
  --contact_field-text-color: #76787a;
  --contact_info_sec-bg: #EFEFEF;
  --book-card-details-bg: rgba(14, 2, 141, 1);
  --twitter-icon-color: rgb(0, 195, 255);
  --review-btn-color: #0d172a;
  --box-heading-color: #3a405b;
  --message-icon-color: pink;
  --emty-card: rgba(68, 102, 242, .05);
  --emty-card-body-bg-color: #eee;
  --bs-body-color: #212529;
  --heasing-icon-shadow: #ee028985;
  --branch-container-bg: rgba(232, 232, 232, 1);
  --deactive-color: #fa098e;
  --child-button: #f5f5f5;
  --toolbar-button-active: #adadad;
  --finance-input-border: #dddddd;
  --preview-button-hover: rgba(0, 0, 0, 0);
  --antd-pagination-a: rgba(0, 0, 0, 0.88);
  --service-status-deactive: #c7c0c0;
  --ant-pagination-item-active-bg: #007bff;
  --select-item-box-shadow: rgba(0, 0, 0, 0.1);
  --vendor-sidebar-outer-div: #e26569;
  --vendor-sidebar-link-active: rgba(256, 256, 256, 0.6);
  --team-card-ancor: #337ab7;
  --preview-button-text: rgb(24, 23, 23);
  --vendor-header-outer-div: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  --box-shado-all-dashboard: drop-shadow(0px 1px 10px 1px rgba(0, 0, 0, 0.05));
  --box-shado-all-dashboard-is-visible: drop-shadow(0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12));
  --vendor-profile-right-part-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --head-text: black;
  --fitAndAct-showListRightProductInfoDiv-bg: whitesmoke;
  --search-type-bg: black;
  --surface-color: white !important;
  --input-field-color: black;
  --icon-color: transparent;
  --span-tag-text-color: black;
  --notification-bg-color: #f9f9f9;
  --warning-container-border: #D9D9D9;
  --calender-footer-btn: #EE0A89;
  --offer-header-bg-color: #FFA1C6;
  --offer-header-font-color: #290215;
  --navber-color: #2E2E2E;
  --category-hover-color: #F14A9B;
  --service-bg-color: #F04A97;
  --webupdate-bg-color: rgb(251, 87, 107, 0.2);
  --spa-image-outer-card-hover: linear-gradient(180deg, rgba(118, 118, 118, 0.1) 10%, #760E45 100%)
}