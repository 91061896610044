.cancel_deletion_timerbox {
  background: var(--cancel_deletion_timerbox-bg);
  border-radius: 15px;
  width: 87%;
  margin-left: 7% !important;
}

.heading {
  text-align: center;
}

@media (max-width:575px) {
  .cancel-deletion-request-containt-div {
    margin-left: 20px;
  }

  .clockImage {
    height: 20px;
    width: 20px;
  }
}

@media (max-width:284px) {
  .cancel_deletion_timerbox>.col-1 {
    width: 13%;
  }

  .cancel_deletion_timerbox>.col-11 {
    width: 87%;
  }
}

@media (max-width:480px) {
  .user-inner-content-scrollar-deletion-request>div>.heading>h5 {
    font-size: 18px !important;
  }
}

@media (max-width:575px) {
  .user-inner-content-scrollar-deletion-request>div>.heading>h5 {
    font-size: 16px !important;
  }
}