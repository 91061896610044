
.back-to-home {
  color: var(--black-color);
  text-decoration: underline;
}

.back-to-home:hover {
  color: var(--sura-text-color);
}
.bussinessInfo-text-info{
  font-weight: 549!important;
}

@media (min-width: 992px) {
  .col-lg-6 {
    height: auto !important;
  }
}

.div-container {
  height: 100vh;
}

.login-form .term-condition {
  font-size: 10px !important;
}

.signup-form-container {
  padding: 50px;
  border: 1px solid var(--white-btn-bg-color);
  background: var(--white-btn-bg-color);
  align-items: center;
  justify-content: center;
}

.ant-checkbox .ant-checkbox-input {
  position: absolute;
  inset: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  margin: 0;
  width: 3px;
}

.account a {
  font-size: 16px;
  font-weight: 400;
}

.account {
  display: flex;
  justify-content: center;
}

.filter-content hr {
  width: 100%!important;
}

::placeholder {
  font-size: 10px;
}

.text-area,
.custom-select,
.password-field,
.login-form input,
Input.Password {
  border-radius: 6px;
  padding: 4px 18px;
}

.term-condition #myCheckbox {
  font-size: 15px;
}

.term-condition .form-check-input {
  cursor: pointer;
  padding: 0;
}

.term-condition .form-check-input:checked {
  cursor: pointer;
  border-color: var(--review-rating-active-btn) !important;
  background-color: var(--review-rating-active-btn) !important;
}

small {
  font-size: 12px !important;
}

.login-form Button {
  background: var(--bg-color);
  box-shadow: 0px 3px 12px 0px var(--sign-in-box-shadow);
  color: var(--white-text-color);
  margin-top: 30px;
  letter-spacing: 1.4px;
  text-transform: capitalize;
  display: inline-block;
  border: none;
}

.login-form a {
  color: var(--black-color);
  text-decoration: none;
}
.login-form a:hover{
  color: rgba(238, 10, 137, 1);
  text-decoration: none;
}

.signup-form-heading {
  color: var(--review-rating-active-btn);
  white-space: inherit;
}

.signup-form-heading2 {
  margin-top: -1vh;
}

.signUp-customeSelect {
  border-radius: 0px !important;
  height: 2.1em;
}

.signUp-customeSelect-ant {
  height: 2.5em !important;
  width: 100%;
}

.signUp-customeSelect-ant .ant-select-selector {
  border-radius: 6px !important;
}

.login-form .term-condition {
  font-size: 13px !important;
}

.sign-up-user-sign-btn-div {
  display: flex;
  justify-content: center;
}

.sign-up-next-btn-div {
  display: flex;
  justify-content: center;
}

.sign-up-next-btn-div:hover {
  color: var(--white-text-color);
}

.sign-up-vendor-sign-btn-div {
  display: flex;
  justify-content: center;
}

.breadcrumbs {
  border-radius: 0.3rem;
  display: inline-flex;
}

.confirm-number {
  margin: 0px 10px 0px 0px;
  border-radius: 50%;
  border: 2px solid#333;
  padding: 3px 10px 3px 10px;

}

.bussinessInfo-text.disable {
  color: var(--bread-com);
}

.bussinessInfo-text.active {
  color: var(--bussinessInfo-text-color);
}

.confirm-number.active {
  border: 2px solid var(--bussinessInfo-text-color);
}

.confirm-number.disable {
  border: 2px solid  var(--bread-com);
}

.bussinessInfo {
  border-right: 1px solid var(--step-icon-bg);
}

.vendor-breadcrumbs-heading span {
  font-weight: 600;
}

.vendor-breadcrumbs-heading {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solidvar(--step-icon-bg);
  border-top: 1px solidvar(--step-icon-bg);
}

.disable-country-select{
  pointer-events: none ;
  width: 100% ;  
}
.bussinessInfo-clickable {
  cursor: pointer !important;
}
.disable-country-name{
  pointer-events: none;

}
@media (max-width:992px) {
  .div-container {
    height: auto;
  }

  .div-container>.inner-div {
    margin-top: 0.5rem !important;
  }

  .div-container>.inner-div>.row>.signup-image-col-6 {
    margin-left: 1%;
    width: 99%;
  }
}

@media (max-width:500px) {
  .bussinessInfo-text {
    margin-left: 10px;
  }
}

.col-lg-6>.signup-form-container{
  max-height: 100vh;
  overflow: auto;
}

.col-lg-6>.signin-form-container {
  max-height: 100vh;
  overflow: auto;
}

.col-lg-6>.signup-form-container::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.col-lg-6>.signin-form-container::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.col-lg-6>.signup-form-container::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color);
  border-radius: 1em;
}
.col-lg-6>.signin-form-container::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.col-lg-6>.signup-form-container::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}
.col-lg-6>.signin-form-container::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}

.col-lg-6>.signup-form-container::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}
.col-lg-6>.signin-form-container::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}

.signUp-home-arrow-div{
  display: flex;
  justify-content: center;
  font-size: 15px;
  padding-bottom: 10px;
}
.signUp-home-arrow-div>i{
  display:flex;
  font-size: 24px;
}
@media (max-width:991px){
  .col-lg-6>.signup-form-container {
    max-height: unset;
  }
}
@media (max-width:768px){
  .confirm-number{
    padding: 1px 5px 0px 5px;
  }
}
@media (max-width:320px){
  .vendor-breadcrumbs-heading{
    display: block;
  }
  .bussinessInfo-text{
    padding-top: 10px;
    width: 100%;
  }
  .signup-form-heading2{
    line-height: 36px!important;
  }
  .signup-form-heading{
    font-size: 22.5px;
  }
  .navigate-signup,.signup-form-container label,.account{
    font-size: 15px;
  }
  .recaptcha iframe,.recaptcha div{
    width: 100%!important;
  }
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: none !important; 
  display: flex !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 0px !important; 
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 84px;
  border: 1px solid #6d6f6d2b !important;
  border-radius: 6px !important;  
  width: 100%;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel],.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=tel]{
  padding-left: 84px;
  border: 1px solid #6d6f6d2b !important;
  border-radius: 6px !important;  
  width: 100%;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input:focus,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text]:focus,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel]:focus {
  border: 1px solid #6c5f5f33 !important;
  border-radius: 6px !important;
  outline: none;
}
.intl-tel-input{
  width: 100% !important;
}
.flag-container{
  width: 100%;
}