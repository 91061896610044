.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
}
.checkoutHeader-delivery-address-section,
.checkoutHeader-payment-section,
.checkoutHeader-finish-section {
    display: flex;
    gap: 5px;
}
.checkout-header-navbar {
    padding: 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 5px 20px ;
    align-items: center;
}
.checkout-header-navbar .logo-section{
    margin-left: 3rem 
}
#progress-bar {
    display: table;
    width: 100%;
    margin: 0;
    padding: 15px 15px 0;
    table-layout: fixed;
    width: 100%;
    counter-reset: step;

    li {
        list-style-type: none;
        display: table-cell;
        width: 33.33%;
        float: left;
        font-size: 16px;
        position: relative;
        text-align: center;

        &:before {
            width: 25px;
            height: 25px;
            color: #212121;
            content: counter(step);
            counter-increment: step;
            line-height: 25px;
            font-size: 14px;
            border: 1px solid #efefef;
            display: block;
            text-align: center;
            margin: 0 auto 10px auto;
            border-radius: 50%;
            background-color: #fff;
        }

        &:after {
            width: 100%;
            height: 4px;
            content: '';
            position: absolute;
            background-color: grey;
            top: 11px;
            left: -50%;
            z-index: -1;
        }

        &:first-child:after {
            content: none;
        }

        &.step-done {
            color: green;
         
            &:before {
                border-color: green;
                background-color: green;
                color: #fff;
                content: "✓";
                font-family: "Arial";
            }

            &+li:after {
                background-color: green;
            }
        }

        &.step-active {
            color: green;

            &:before {
                border-color: green;
                color: green;
                font-weight: 700;
            }
        }
    }
}

@media (max-width:421px){
    .checkout-header-navbar .logo-section{
        margin-left: 1rem 
    }
}