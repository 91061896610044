
.datePicker {
  width: 100%;
}
.available {
  margin-top: -28px;
}
.addService-checkbox .checkbox-button {
  margin-top: -15px;
}

.custom-link {
  color: var(--sura-text-color);
  border-bottom: linear-gradient(var(--sura-text-color), var(--custom-link));
}
.custom-link:hover {
  color: var(--sura-text-color)!important;
  border-bottom: linear-gradient(var(--sura-text-color), #FD6266)!important;
}
.service-review-file .preview-pile-modal-outer-div{
  --bs-modal-width: 60% !important;
}

.teamperson-checkbox-span{
  padding: 0 7px;
}
.footerButton {
  width: 100%;
  text-align: right;
}
a:hover {
  color: var(--sura-text-color);
}
.team-person {
  width: 100%;
}
.add-slot {
  display: flex;
  justify-content: right;

  margin-top: 16px;
  display: flex;
  justify-content: right;

}

.ant-input {
  border-radius: 6px !important;
}

.service-image-field .custom-file-upload {
  padding: 8px 20px;
}

.finance-cheque-field-after-cheque-outer-div .editbranchprofile_addbutton{
    left: -8px;
    position: absolute;
    bottom: 5px;
    transform: translateX(-50%);
    opacity: 0;
}

.ant-input-number {
  border-color: var(--step-icon-bg) !important;
  border-radius: 6px !important;

}
.backgoround-button {
  width: 100px;
  background: linear-gradient(var(--sura-text-color), var(--custom-link)) !important;
  color: var(--white-text-color) !important;
}
.service-add-slot {

  width: 85px;
  height: 30px;
  font-size: 10px;
  background:var(--warning-container-border) !important;
  color:var(--bg-color) !important;
  display: flex !important;
  align-items: center;
  
}
.service-add-slot:hover {
  background: var(--warning-container-border) !important;
  color:var(--bg-color) !important;
  display: flex !important;
  align-items: center;
}
.times-icon {
  color:var(--times-icon);
}

.addService-checkbox {
  margin-top: 25px;
}
.addService-checkbox .checkbox-group {
  display: flex;
  justify-content: space-between;
}
.checkbox-button input:checked+span {
  background-color: var(--sura-text-color);
  border-color: var(--sura-text-color);
  color: var(--white-text-color);
}
.custom-label {
  font-size: 16px;
  font-weight: 500;
}
.custom-astric {
  color:var(--red-color);
  margin-left: 2px;
}

.customteamMember {
  margin-left: 8px;
  background-color: var(--step-icon-bg);
  display: flex;
  align-items: center;
  border-radius: 12px 0 0 12px;
  width: 120px
}
.customteamMemberOnHover {
  margin-left: 8px;
  background-color:var(--step-icon-bg);
  display: flex;
  align-items: center;
  border-radius: 12px 0 0 12px;
  width: 100%
}
@media only screen and (max-width: 820px) {
  .te {
    margin-top: -30px;
  }

  .table-container {
    overflow-x: auto;
  }
  .add-slot {
    text-align: right;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;

  }
  .team-person {
    width: 100%;
  }
  .personCheckBox {
    margin-top: 2px;
  }
  .p-headig {
    font-size: 13px;
  }
}
@media only screen and (max-width: 576px) {
  .p-headig {
    font-size: 13px;
  }

  .table-container {
    overflow-x: auto;
    max-width: 100%;
  }
  .add-slot {
    text-align: right;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;

  }
  .team-person {
    width: 100%;
  }
  .personCheckBox {
    margin-top: 2px;
  }
  .customteamMember {
    margin-left: 8px;
    background-color: var(--step-icon-bg);
    display: flex;
    align-items: center;
    border-radius: 12px 0 0 12px;
    width: 68vw
  }
  .customteamMemberOnHover {
    margin-left: 8px;
    background-color: var(--step-icon-bg);
    display: flex;
    align-items: center;
    border-radius: 12px 0 0 12px;
    width: 68vw;
  }
}
@media only screen and (max-width: 282px) {
  .customteamMember {
    margin-left: 8px;
    background-color: var(--step-icon-bg);
    display: flex;
    align-items: center;
    border-radius: 12px 0 0 12px;
    width: 58vw
  }
  .customteamMemberOnHover {
    margin-left: 8px;
    background-color: var(--step-icon-bg);
    display: flex;
    align-items: center;
    border-radius: 12px 0 0 12px;
    width: 58vw;
  }
}
.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
  
}
.modal-body .ant-form-item-required span{
  color: var(--input-field-color);
}
.finance-outer-div .ant-form-item {
  margin-bottom: 10px !important;
}
.finance-outer-div .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 1px;

}
.ant-select-single .ant-select-selector {
  border-radius: 0px;
}
.finance-outer-div .ant-form p {
  margin-bottom: 0.2rem;
}
.add-service-vendor-table>.ant-table-wrapper>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content>table>.ant-table-thead>tr {
  border-width: 0px !important;
  border-style: hidden;

}
.add-service-vendor-table>.ant-table-wrapper>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content>table>.ant-table-thead>tr>th {
  border-width: 0px !important;
  border-style: hidden;
}
.add-service-vendor-table>.ant-table-wrapper>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content>table>.ant-table-thead>tr>td {
  border-width: 0px !important;
  border-style: hidden;
}
.add-service-vendor-table>.ant-table-wrapper>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content>table>.ant-table-tbody>tr>td {
  border-width: 0px !important;
  border-style: hidden;
}
.ant-form-item-explain-error{
  font-size: 12px !important;
  margin-top: 5px !important;
}

 .ant-form-item-control-input-content>.quill>.ql-container>.ql-editor{
      height: 15em;
 }
.ant-form-item-explain-error{
  display: flex !important;
}

.CustomCheckboxWithImage-img{
  border-radius: 50%;
}
.is-disable-eye-icon-service-section {
  color: #ccc; 
  cursor: not-allowed !important; 
  pointer-events: none; 
}
.service-previewbutton-Div{
  display: flex;
  justify-content: center;
}