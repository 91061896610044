.datepicker-inline {
  width: 100%;
}

.datepicker-days {
  width: 100%;
}

.datepicker table {
  width: 100%;
}

.carousel-inner {
  min-height: 250px;
}

.carousel-indicators li {
  background-color: var(--bg-black-color);
  position: relative;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: 0px;
  flex: 0 1 auto;
}

.carousel-indicators li.active {
  background-color: var(--red-color);
}

#date-popup>div {
  position: relative !important;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}

.ant-picker-header {
  padding: 0px 20px !important;
}

.ant-modal-content {
  width: 100%;
}

.book-now-modal-calendar-col-9 .date-popup-363 .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panel-focused .ant-picker-footer {
  display: none;
}

.custom-datepicker-div .ant-picker-footer {
  display: none;
}

.pick-time-container {
  max-height: 330px;
  overflow-y: visible;
  overflow-x: hidden;
}

.book-now-modal-single-booking>.ant-modal-content {
  padding: 20px 16px !important;
}

.pick-time {
  width: 63px;
  height: 27px;
  top: 2032px;
  left: 1096px;
  border-radius: 50px;
  text-align: center;
  background: rgba(248, 248, 248, 1);
}

.custom-datepicker .ant-picker-outlined {
  background: var(--white-btn-bg-color);
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  visibility: hidden;
}

.centered-title-modal .ant-modal-title {
  text-align: center;
}

.book-now-modal-date-picker>.ant-modal-content>.ant-modal-body>.book-now-modal-date-picker-outer-div>.book-now-modal-calendar-col-9>div>.ant-picker-dropdown>.ant-picker-panel-container>.ant-picker-panel-layout>.ant-picker-panel>.ant-picker-footer {
  display: none;
}

.centered-title-modal .ant-modal-footer {
  display: none;
}

.custom-datepicker-div .ant-picker-outlined {
  visibility: hidden;
}

.custom-datepicker .ant-picker-input {
  display: none;
}

.display-service-button :hover {
  background-color: var(--sura-text-color);
  color: var(--white-text-color);
  border: var(--sura-text-color)
}

.bookNow .fav-modal {
  width: 122%;
}

.time-pick-btn .btn-div .btn:hover {
  background-color: var(--sura-text-color);
  color: var(--white-text-color);
  border: var(--sura-text-color);
  height: 2.2rem;
}

.time-pick-btn .btn-div {
  text-align: center;
}

.time-pick-btn .btn-div .btn {
  height: 2.2rem;
}

.selected-time {
  background-color: var(--sura-text-color);
  cursor: pointer;
  color: var(--white-text-color);
  font-weight: bold;
}

.pick-time {
  cursor: pointer;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  color: var(--white-text-color);
  background: var(--sura-text-color) !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--sura-text-color) !important;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background: var(--sura-text-color) !important;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--sura-text-color);
  border-color: var(--sura-text-color);
  color: black;
}

.book-now-modal-conform-btn-div {
  display: flex;
  justify-content: center;
}

.book-now-modal-pick-time-heading {
  font-weight: 500;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 0.5fr));
  gap: 0px;
}

@media (max-width:642px) and (min-width:445px) {
  .ant-picker-panel-layout {
    display: flex;
    justify-content: center;
  }
}

@media (max-width:445px) {
  .pick-time-container {
    max-height: 123px;
  }

  .book-now-modal-date-picker-outer-div {
    display: flex;
    justify-content: center;
  }

  .book-now-modal-date-picker-outer-div .col-9 {
    flex: 0 0 auto;
    width: 86%;
  }

  .book-now-modal-date-picker-outer-div .col-3 {
    text-align: center;
    width: 100%;
  }

  .book-now-modal-calendar-col-3 {
    margin-top: 10px;
  }

  .book-now-modal-pick-time-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 0.5fr));
    gap: 0px;
  }

  .ant-picker-panel-layout {
    display: flex;
    justify-content: center;
  }
}

.centered-title-modal div .ant-modal-content .ant-modal-footer {
  display: none;
}

.btn-disable {
  cursor: none;
  pointer-events: none;
  opacity: 0.5;
}

.ant-modal-content>.ant-modal-header>.ant-modal-title {
  display: flex;
  justify-content: center;
}

.book-now-modal-single-booking>.book-now-modal-calendar-col-9>.ant-picker-outlined {
  border: none;
}

@media (max-width:472px) {
  .ant-picker-panel {
    width: 410px;
    overflow: scroll;
  }

  .ant-picker-panel>.ant-picker-datetime-panel {
    width: 410px;
    overflow: scroll;
  }
}

.hide-book-btn-modal-new>div>.ant-picker-dropdown>.ant-picker-panel-container>.ant-picker-panel-layout>div>.ant-picker-footer{
display: none !important;
}
.branch-name-book-modal{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.branch-name-heading{
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.ant-select-selection-item{
  font-size: 14px !important;
}
.hide-book-btn-modal-new>div>.ant-picker-dropdown>.ant-picker-panel-container>.ant-picker-panel-layout>div>.ant-picker-footer {
  display: none !important;
}

.pick-time {
  padding: 3px 2px !important;
}

.pick-time-container {
  overflow-y: scroll;
}

.pick-time-container::-webkit-scrollbar {
  width: 8px;
}

.pick-time-container::-webkit-scrollbar-track {
  background:var(--sura-text-color)
}

.pick-time-container::-webkit-scrollbar-thumb {
  background-color:var(--sura-text-color);
  border-radius: 10px;
  border: 2px solid var(--sura-text-color);
}

.pick-time-container::-webkit-scrollbar-thumb:hover {
  background-color:var(--sura-text-color);
}

.pick-time-container {
  scrollbar-width: thin;
  scrollbar-color:var(--sura-text-color);
}

.disable-time-slot {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width:385px) {
 .ant-picker-dropdown .ant-picker-content{
  width: calc(100% - 15px); 
 } 
}