
.rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 0 !important;
}
.rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
}
.rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 0 !important;
    position: relative;
}

.calendar-row {
    background: var(--row-box-color);
    border-radius: 11px !important;
    display: flex;
    justify-content: space-between;

}
.calendar-row .calendar-col {
    padding: 7px 10px 6px;
    font-weight: 600;
}
.rbc-btn-group:first-child {
    display: flex !important;
    gap: 5px;
}
.rbc-btn-group:first-child button {
    padding: 2px 10px;
    border: 1px solid var(--dashboard-filter-inner-div-bg);
    background-color: var(--child-button);
}
.rbc-btn-group:first-child button:nth-child(2),
.rbc-btn-group:first-child button:nth-child(3) {
    font-size: 0px;
}
.rbc-btn-group:first-child button:nth-child(2)::before {
    content: "\003C";
    font-size: 20px;
    margin-top: -3px;
    display: inline-block;
    font-weight: 900;
}
.rbc-btn-group:first-child button:nth-child(3)::before {
    font-size: 20px;
    content: "\003E";
    margin-top: -3px;
    display: inline-block;
    font-weight: 900;
}
span.rbc-btn-group:first-child button:nth-child(1) {
    order: 2 !important;
    background:var(--white-color);

    margin-left: 11px;
}
.rbc-toolbar-label {
    font-size: 25px;
    font-weight: bold;
}
.calender-outer-container {
    margin-top: 78px;
    padding-left: 21px;
    padding-right: 11px;
}

.space-between-feilds-calander {
    margin-bottom: 8px;
}
.calender-comment-input {
    height: 60px;
}
.calander-footer {
    border-top: none !important;
    display: flex !important;
    justify-content: center !important;

}
.calender-footer-button {
    width: 100px !important;
    border-color: var(--calender-footer-btn) !important;
    background-color: var(--calender-footer-btn) !important;
    margin: 2px;
}
.calender-footer-button-cancel {
    color:var(--black-color);
    width: 100px !important;
    border-color: var(--black-color) !important;
    background-color:var(--white-color) !important;
    margin: 2px;
}
.calender-footer-button-cancel:hover {
    color: var(--black-color);
}
.calander-feilds-border {
    border-radius: 6px !important;
}

.required-asterisk {
    color:var(--red-color);
    margin-left: 5px;
}
.calender-footer-button-delete {
    margin: 2px;
    width: 100px !important;
}
.validation-message {
    color: var(--red-color);
}
.validation-message-display {
    display: none;
}

.calender-button-class,
.calender-button-class:hover {
    background-color: var(--calender-footer-btn) !important;
    color: var(--white-text-color);
}
.rbc-header span {
    font-size: large !important;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event .custom-width-event {
    border: 1px solid var(--white-color);
    border-color: var(--white-color);
    display: flex;
    max-height: 100%;
    min-height: 40px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}
.rbc-event,
.rbc-day-slot .rbc-background-event .custom-width-event-month {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    border-radius: 5px;
    color: var(--white-text-color);
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.calender-team-member-option::after {
    background-color: var(--red-color) !important;
}
.calander-filter-select {
    border: 1px solid var(--warning-container-border) !important;
    border-radius: 2px;
    background-color: var(--white-color) !important;
}
.dnCalender-heigth {
    min-height: 100vh;
    padding: 16px;
}
.calender-ant-select-search {
    margin-top: 3px;
    min-width: 14vw !important;
    text-transform: capitalize !important;
}
.calender-ant-select-form {
    width: 100% !important;
    height: 2.7em !important;
}
.calender-ant-select-form .ant-select-selector {
    border-radius: 6px !important;
}

.calander-footer>.calender-footer-button-cancel,.calander-footer>.calender-footer-button-cancel:hover,.calender-footer-button-cancel:active{
    color: black !important;
}
@media  (max-width: 820px) and (min-width:575px)  {
    .calender-button-class {
        background-color:var(--calender-footer-btn) !important;
        color: var(--white-text-color);
        font-size: 14px;
    }
}

@media only screen and (max-width: 575px) {
    .rbc-toolbar button:active,
    .rbc-toolbar button.rbc-active {
        font-size: 12px;
        background-image: none;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        background-color: var(--step-icon-bg);
        border-color: var(--toolbar-button-active);
    }
    .rbc-btn-group>button:not(:first-child):not(:last-child) {
        border-radius: 6px;
        font-size: 12px;
    }
    .rbc-btn-group>button:last-child:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 12px;
    }
    .rbc-btn-group>button:first-child:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 12px;
    }
     .rbc-toolbar .rbc-toolbar-label {
        flex-grow: 1;
        padding: 0 10px;
        text-align: center;
        font-size: 12px;
    }
    .rbc-current .rbc-button-link {
        font-size: 10px
    }
    .rbc-date-cell .rbc-button-link {
        font-size: 10px;
    }
    .rbc-header .columnheader {
        font-size: 10px;
    }
    .rbc-time-slot .rbc-label {
        font-size: 10px;
    }
    .rbc-event-content {
        font-size: 10px;
    }
    .rbc-button-link span {
        font-size: 10px;
    }
    .rbc-btn-group {
        margin-bottom: 5px;
    }
    .rbc-toolbar-label {
        margin-bottom: 5px;
    }
    .dnCalender-heigth {
        height: 100vh;
        padding: 1px;
    }

    .calender-button-class {
        background-color: var(--calender-footer-btn) !important;
        color: var(--white-text-color);
        font-size: 12px;
        width: 30vw;
        height: 6vh;
    }
    .cross-button-mobile {
        text-align: center;
    }
    .rbc-show-more {
        font-size: 50% !important;
    }
}

@media only screen and (max-width: 363px) {
  
    .calender-ant-select-search {
        width: 36vw !important;
        text-transform: capitalize !important;
    }
}
.custom-modal-header-row {
    height: 3em;
    font-size: 20px;
    font-weight: 600;
}
.modal-body-custom-line {
    border-top: 1px solid #D9D9D9;
}
.rbc-row-content {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    z-index: 0 !important;
}
@media (max-width:575px) {
    .calendar-row {
        background: #ea247f1a;
        border-radius: 11px !important;
        display: flex;
        justify-content: left;
    }
    .calendar-col {
        display: none;
    }
    .calendar-row>.calendar-col {
        width: 100% !important;

    }
    .calendar-col>.calendar-col.ant-select {
        width: 100% !important;
    }
    .user-inner-content-scrollar>.custom-width-event>.rbc-toolbar>.rbc-btn-group:first-child {
        padding: 5px;
        display: flex !important;
        justify-content: center;
        width: 100%;
        gap: 20px;
    }
    span.rbc-btn-group:first-child button:nth-child(1) {
        margin-left: 0px;
    }
   
}
