.headerLine {
    border-bottom: none !important;
}
.footerLine {
    border-top: none !important;
    margin-bottom: 20px !important;
}

.cancelbutton {
    background: var(--white-btn-bg-color) !important;
    border-color: var(--sura-text-color) !important;
    color: var(--black-color);
    width: '100px'!important;
}
.cancelbutton:hover {
    background: var(--white-btn-bg-color) !important;
    border-color: var(--sura-text-color) !important;
    color: var(--black-color) !important
}
.deletebutton {
    background: linear-gradient(var(--sura-text-color), var(--crrote-color)) !important;
    color: var(--white-text-color) !important;
    width: 100px;
    font-size: 18px;
    margin-left: 1vw;
}
.customModal {
    width: 400px !important;
    margin: auto !important;
    top: 50% !important;
    border: none !important;
    transform: translateY(-50%) !important;
}
.delete-pop-up-message{
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;
}
.height100px{
    height: 100px;
}
@media only screen and (max-width: 600px) {
    .customModal {
        width: 300px !important;
        margin: auto !important;
        top: 5% !important;
        border: none !important;
        transform: translateY(-50%) !important;
    }
}
@media only screen and (max-width: 768px) {
    .customModal {
        width: 400px !important;
        margin: auto !important;
        top: 35% !important;
        border: none !important;
        transform: translateY(-50%) !important;
    }
}
.cancelbutton,
.deletebutton {
    width: 100px !important;
}
.cancelbutton {
    background: var(--white-btn-bg-color) !important;
    border-color: var(--sura-text-color) !important;
    color: var(--black-color);
}
.cancelbutton:hover {
    background: var(--white-btn-bg-color) !important;
    border-color: var(--sura-text-color) !important;
    color: var(--black-color) !important;
}
.deletebutton {
    background: linear-gradient(var(--sura-text-color), var(--crrote-color)) !important;
    color: var(--white-text-color) !important;
    font-size: 18px;
}