.add-to-fav-container3 {
  background: linear-gradient(0deg, var(--white-btn-bg-color), var(--white-btn-bg-color));
}

.add-to-fav-container {
  text-transform: capitalize;
}

.service-heart-icon {
  color: var(--review-rating-active-btn);
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
}

.add-to-fav-contain {
  border: none;
}

.heart-icon,
.share-icon1 {
  margin-right: 0.5rem;
  height: 24.98px;
  top: 379.6px;
  left: 665.3px;
  font-size: 22px;
  color: var(--review-rating-active-btn);
}

.display-service-button1 {
  background-color: var(--sura-text-color);
  color: var(--white-text-color);
}

.display-service-button1:hover {
  background-color: var(--sura-text-color);
  color: var(--white-text-color);
}

@media screen and (max-width : 575px) {
  .favourite-shop-action {
    display: flex !important;
    justify-content: space-evenly !important;
  }
}

@media (max-width:575px) and (min-width:476px) {
  .favourite-shop-action>.shop-card-heart-shop {
    margin-top: 10px;
  }
}

@media (max-width:575px) {
  .favourite-shop-action>.shop-card-heart-shop {
    font-size: 15px;
  }

  .favourite-shop-action>span>.shop-card-share-shop {
    font-size: 15px !important;
  }
}

.shop-favourite-outer-p>p {
  margin-top: 0rem !important;
}

.shop-card-address {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.favourite-service-rating-address>.shop-card-address {
  display: inline-block !important;
}

.favourite-service-rating-and-time-div>.shop-card-address {
  display: inline-block !important;
}