.accountDeletion_fa_icon_back {
  font-size: 2rem;
  font-size: bold;
  color: var(--black-color);
}
.heading {
  padding-top: 5vh;
}
.accountDelete_checkboxcontaint {
  margin-left: -2vw;
}
.accountDelete_checkboxcontaint>p {
  margin-bottom: 0px;
  margin-top: 0.2rem;
}
.accountDelete_confirmDeletionButton_Div {
  display: flex;
  justify-content: center;
}
.accountDelete_confirmDeletionButton {
  text-align: center;
  font-weight: bold;
  text-align: center;
  background: var(--bg-color);
  color: var(--white-text-color) !important;
  border: none;
  border-radius: 10px;
  height: 7vh;
  font-size: 18px;
}
@media (max-width:997px) {
  .accountDelete_checkboxcontaint {
    margin-left: 0vw;
  }
}
@media (max-width:455px) {
  .accountDelete_checkboxcontaint {
    margin-left: 0vw;
  }
}
@media (max-width:390px) {
  .deletion-request-inner-row>.col-1 {
    width: 13%;
  }
  .deletion-request-inner-row>.col-11 {
    width: 87%;
  }
}
@media (max-width:575px) {
  .account-deletion-outer-div>.row>.col-10>.text-center>h5 {
    font-size: 16px !important;
  }
  .accountDelete_checkboxcontaint>p {
    font-size: 14px;
  }
}