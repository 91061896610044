@import "../../../assets/styles/style.css";

.accountDetails_Address_Heading {
  font-weight: bold;
  font-size: 18px;
}

.accountDetails_saveButton_Div {
  display: flex;
  justify-content: right;
}

.accountDetails_saveButton_Div button {
  text-align: center;
  font-weight: bold;
  text-align: center;
  background: var(--bg-color) !important;
  color: white !important;
  border: none;
  border-radius: 10px;
  width: 12vw;
  font-size: 18px;
}

.countryCode-class {
  background: white;
  border: 1px solid #6d6f6d2b !important;
  border-radius: 6px !important;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  padding-left: 9px !important;
  height: 2.1rem;
}

.stdropdown-tool {
  display: none;
}

.stdropdown-menu {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: white;
  max-height: 138px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: 6px;
}

.accountDetails_saveButton_Div button :hover {
  text-align: center;
  font-weight: bold;
  text-align: center;
  background: var(--bg-color) !important;
  color: white !important;
  border: none;
  border-radius: 10px;
  width: 12vw;
  font-size: 18px;
}

@media (max-width:420px) {
  .accountDetails_saveButton_Div button {
    width: 15vw;
    font-size: 22px;
  }
}

@media (max-width:292px) {
  .accountDetails_saveButton_Div button {
    width: 20vw;
    font-size: 12px;
  }
}

@media (max-width:177px) {
  .accountDetails_saveButton_Div button {
    width: 25vw;
  }
}

@media (max-width:150px) {
  .accountDetails_saveButton_Div button {
    width: 30vw;
  }
}

.btn:hover {
  color: var(--white-text-color);
}

.vendor-account-details-input-field {
  border-radius: 6px;
}

@media (max-width:992px) {
  .account-details-vanue-email-div {
    margin-top: 3vh;
  }
}

.editBranch-add-button-div {
  color: var(--black-color);
  position: absolute;
  bottom: 15px;
  left: 30%;
  transform: translateX(-50%);
  border-radius: 7px;
  border: 0.5px solid var(--graph-border-bolor)
}

.account-details-image-outer-div {
  position: relative;
}

.account-details-image {
  width: 60%;
}

.editBranch-add-button-div:hover {
  color: var(--black-color) !important;
  border: 0.5px solid var(--graph-border-bolor);
}

.vendoraccountdetailsUploadImageDiv {
  margin-top: -2vh;
  cursor: pointer;
}

.vendor-account-details-input-field {
  border-radius: 6px;
}

.editbranchprofile_img_add_div {
  margin-top: -5vh;
  position: relative;
}

.accountDetails-add-button-div {
  bottom: 0px;
  margin-left: -1vw;
}

.editbranch-shopImage1 {
  border-radius: 6px;
  aspect-ratio: 3/2;
  object-fit: cover;
  object-position: center;
}

@media (max-width:575px) {
  .accountDetails-add-button-div {
    bottom: 0px;
    margin-left: 0px;
  }

  .accountDetails-add-button-div>button {
    margin-left: 20px !important;
  }

  .p-2>.account-details-outer-div {
    margin-top: 70px;
  }
}

.image-div {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.cross-icon {
  color: var(--black-color);
  background: var(--inner-background-db);
  padding: 1px 11px;
  border-radius: 5px;
  position: absolute;
  right: 13px;
  top: -8px;
  cursor: pointer;
}

.editbranchprofile_addbutton,
.editbranchprofile_addbutton:hover {
  color: var(--black-color) !important;
  border: 0.5px solid var(--graph-border-bolor) !important;
  color: var(--bg-color);
  position: absolute;
  bottom: 5px;
  transform: translateX(-50%);
  border-radius: 7px;
  margin-left: 50px;
}

@media (max-width:500px) {
  .account-details-outer-div {
    font-size: 15px;
  }

  .accountDetails_Address_Heading {
    font-size: 17px;
  }
}

@media (max-width:575px) {
  .accountDetails-add-button-div {
    margin-top: 80px !important;
  }

  .editBranch-add-button-div {
    color: var(--black-color);
    position: absolute;
    bottom: -48px;
    left: 11%;
    transform: translateX(-50%);
    border-radius: 7px;
    border: 0.5px solid var(--graph-border-bolor);
  }
}

@media (max-width:575px) {
  .accountDetails_saveButton_Div .btn {
    width: 100%;
  }
}

.bold-txt H1 {
  display: none !important;
}

.editBranch-add-button-div,
.editBranch-add-button-div:hover {
  color: var(--black-color) !important;
  border: 0.5px solid var(--graph-border-bolor) !important;
  color: var(--black-color);
  border-radius: 7px;
}

.accountDetails_TrixDiv>.quill>.ql-container>.ql-editor {
  height: 15em;
}