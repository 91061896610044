.booking-notAvailable {
  padding: 250px;
}

.action_edit {
  color: var(--input-field-color);
}

.filterSvg {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 550;
}

.clear-filter-booking {
  min-width: 6vw;
  width: 100% !important;
  height: 35px !important;
  font-size: 11px !important;
  background: var(--search-filter-input-feild-bg) !important;
  color: var(--black-color) !important;
  box-shadow: 0 2px 5px var(--circle-box-shadow);
}

.option li {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.input-booking {
  background-color: var(--search-input-color);
  height: 2.3em;
  padding: 0 22px;
  border: 1px solid var(--warning-container-border);
  border-radius: 5px;
  width: 100%;
  margin-top: 2px;
}

.input-booking::placeholder {
  font-size: 12px;
}

.input-booking:focus {
  outline: none;
  border-color: var(--warning-container-border);
}

.booking-filter-input-feild-date {
  background-color: var(--search-filter-input-feild-bg);
  height: 2.9em;
  padding: 0 5px;
  border: 1px solid var(--warning-container-border);
  border-radius: 5px;
  width: 100%;
  margin-top: 2px;
  font-size: 12px;
}

.booking-filter-input-feild-date:focus {
  outline: none;
  border-color: var(--warning-container-border);
}

.tdclass-booking {
  white-space: nowrap;
  white-space: noWrap;
  font-size: 14px;
  font-weight: 300;
}

.editiconsize-user-booking {
  color: var(--green-color);
}

.editiconsize-user-booking-trash {
  color: var(--red-color);
}

.editiconsize-user-booking-journal {
  color: var(--blue-color);
}

.thclass-booking {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
}

.tdclass-booking-number {
  white-space: nowrap;
  text-align: right;
  font-size: 14px;
  font-weight: 300;
}

.tdclass-booking-number .hl05eU {
  justify-content: right;
}

.thclass-booking-number {
  white-space: noWrap;
  font-size: 15px;
  font-weight: 600;
}

.booking-filter-select {
  margin-top: 2px;
  height: 2.5em;
  max-width: 7vw !important;
  width: 100%;
  border-radius: 8px;
}

.scroller-container {
  overflow-x: hidden;
  overflow-y: auto;
}

.outer-search-feild-booking-buisiness {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px 0px 0px;
}

.mobile-view-filter-icon {
  display: none;
}

@media only screen and (max-width: 820px) {
  .outer-search-feild-booking-buisiness-inner {
    width: 60%;
  }

  .tdclass-booking {
    white-space: nowrap;
    width: 30vw;
    font-size: 14px;
    font-weight: 300;
  }

  .thclass-booking {
    white-space: nowrap;
    width: 30vw;
    font-size: 15px;
    font-weight: 600;
  }

  .tdclass-booking-number {
    white-space: nowrap;
    text-align: right;
    width: 30vw;
    font-size: 14px;
    font-weight: 300;
  }

  .thclass-booking-number {
    white-space: nowrap;
    text-align: right;
    width: 30vw;
    font-size: 15px;
    font-weight: 600;
  }

  .input-booking {
    width: 80px;
  }

  .booking-filter-input-feild-date {
    height: 2.9em;
    padding: 0 5px;
    border: 1px solid var(--warning-container-border);
    border-radius: 5px;
    max-width: 11vw;
    margin-top: 2px;
    font-size: 12px;
  }

  .clear-filter-booking {
    width: 80px !important;
    height: 35px !important;
    font-size: 11px !important;
    background: var(--search-filter-input-feild-bg) !important;
    color: var(--black-color) !important;
    box-shadow: 0 2px 5px var(--circle-box-shadow);
  }

  .booking-filter-select {
    margin-top: 2px;
    height: 2.5em;
    max-width: 30vw !important;
    width: 100%;
    border-radius: 8px;
  }

  .scroller-container {
    /* min-height: 92vh; */
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 600px) {
  .booking-filter-select {
    margin-top: 2px;
    height: 2.5em;
    max-width: 22vw !important;
    width: 100%;
    border-radius: 8px;
  }

  .tdclass-booking {
    white-space: nowrap;
    width: 30vw;
    font-size: 14px;
    font-weight: 300;
  }

  .thclass-booking {
    white-space: noWrap;
    width: 30vw;
    font-size: 15px;
    font-weight: 600;
  }

  .tdclass-booking-number {
    white-space: nowrap;
    text-align: right;
    width: 30vw;
    font-size: 14px;
    font-weight: 300;
  }

  .thclass-booking-number {
    white-space: noWrap;
    text-align: right;
    width: 30vw;
    font-size: 15px;
    font-weight: 600;
  }

  .input-booking {
    width: 80px;
  }

  .booking-filter-input-feild-date {
    height: 2.9em;
    padding: 0 5px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    width: 100%;
    max-width: 20vw;
    margin-top: 2px;
    font-size: 12px;
  }

  .ant-modal-content {
    width: 100%;
    margin-left: 0em;
  }
}

@media only screen and (max-width: 577px) {
  .outer-search-feild-booking-buisiness {
    display: flex;
    justify-content: space-between;
  }

  .outer-search-feild-booking-buisiness-inner {
    width: 70%;
  }
}

@media only screen and (max-width:375px) {
  .outer-search-feild-booking-buisiness-inner {
    width: 60%;
  }

  .booking-filter-select {
    margin-top: 2px;
    height: 2.5em;
    max-width: 29vw !important;
    width: 100%;
    border-radius: 8px;
  }

  .booking-filter-input-feild-date {
    height: 2.9em;
    padding: 0 5px;
    border: 1px solid var(--warning-container-border);
    border-radius: 5px;
    max-width: 29vw;
    margin-top: 2px;
    font-size: 12px;
  }

  .ant-pagination-item {
    min-width: 22px !important;
  }

  .ant-pagination-prev {
    min-width: 22px !important;
  }

  .ant-pagination-next {
    min-width: 22px !important;
  }
}

.ant-pagination .ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: var(--antd-pagination-a);
  background: var(--warning-container-border);
  border-radius: 5px;
}

.ant-pagination .ant-pagination-item-active a {
  color: var(--white-text-color);
  background: var(--sura-text-color);
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: var(--warning-container-border);
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
  border-color: none;
  color: var(--black-color);
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: var(--white-color);
  border-color: var(--sura-text-color);
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  border: 1px solid #d9d9d9;
  margin: 0 2px;
  cursor: pointer;
}

.table>thead {
  vertical-align: top !important;
}

.input-booking::-webkit-inner-spin-button,
.input-booking::-webkit-outer-spin-button,
.input-booking::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.ant-picker-outlined {
  border-color: #D9D9D9 !important;
}

.btn-status {
  padding: 3px 8px;
  border-radius: 6px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.btn-status.confirmed {
  background: #FDF4EB;
  color: var(--colors-warning, #F29339);
}

.cancelled {
  color: #B31312 !important;
  background: #F8E8E8 !important;
}

.btn-status.completed {
  background: #EBF4ED;
  color: #3A974C;
}

.btn-status.expired {
  background: rgba(235, 87, 87, 0.1);
  color: #EB5757;
}

.vendor-booking-team-list-select {
  min-width: 9vw !important;
}

.vendor-team-list-select {
  min-width: 10.5vw !important;
}

.vendor-payment-list-select {
  min-width: 9vw;
}

.vendor-booking-select-tag-filter>.ant-select-selector {
  font-size: 12px;
  padding: 0px 3px !important;
}

.vendor-booking-select-tag-filter .ant-select-arrow .anticon {
  font-size: 12px !important;
}

textarea.form-control {
  min-height: calc(5em + .75rem + calc(var(--bs-border-width)* 2));
}

.thclass-booking-user>.ant-select>.ant-select-selector {
  background-color: #F8F8F8;
}

.thclass-booking-user>.ant-select>.ant-select-selector>.ant-select-selection-placeholder {
  font-size: inherit !important;
  font-weight: 500 !important;
}

@media (max-width:575px) {
  .mobile-view-filter-icon {
    display: block;
  }
}