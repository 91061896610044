@import "../../../assets/styles/style.css";

.finance-previewbutton {
    white-space: nowrap;
}
.vendor-edit-ant-select-form {
    width: 100% !important;
    height: 2.7em !important;
}
.vendor-edit-ant-select-form .ant-select-selector {
    border-radius: 6px !important;
}
.finace-ant-select-form {
    width: 100% !important;
    height: 2.5em !important;
}
.finace-ant-select-form .ant-select-selector {
    border-radius: 6px !important;
}

.finance-cheque-field-after-cheque-outer-div {
    border: var(--bs-border-width) solid var(--bs-border-color);
    display: flex;
    margin-left: 2px !important;
}
.finance-inner-cart-div .countryCode-class{
    font-size: 14px;
    color: var(--bs-body-color);
    height: 2.2rem;
}
.-available-cheque {
    display: flex;
    justify-content: flex-start;
    float: left;
    width: 100%;
    font-size: 14px;
    margin-left: -8px;
}
.finance-cheque-availabe-img-tag {
    display: none;
}
.finance-previewbutton-Div {
    display: flex;
    justify-content: center;
}
.finance-previewbutton,
.finance-previewbutton:hover {
    text-align: center;
    font-weight: 500;
    text-align: center;
    background:var(--white-color);
    background-color:var(--preview-button-hover);
    color:var(--preview-button-text);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 10px;
}
.finance-save-button-div {
    display: flex;
    justify-content: right;
}
.finance-save-button-div button,
.finance-save-button-div button :hover {
    text-align: center;
    font-weight: bold;
    text-align: center;
    background: var(--bg-color)!important;
    color:var(--white-text-color) !important;
    border: none;
    border-radius: 10px;
}
.preview-pile-modal-outer-div {
    --bs-modal-width: 1049px !important;
}
@media (max-width:991px) {
    .finance-previewbutton-Div {
        display: flex;
        justify-content: right;
    }
}
@media (max-width:767px) {
    .finance-previewbutton,
    .finance-previewbutton:hover {
        margin-top: 15px;
        width: 100%;
    }
    .accountDetails_saveButton_Div .btn {
        font-size: 18px;
    }
}
@media (max-width:575px) {
    .field-space-finance{
        margin-top: 5px;
    }
    .finance-col-lg-4-div {
        margin-top: 15px;
    }
    .finance-cheque-field-after-cheque-outer-div {
        width: 99.9%;
    }
    .finance-save-button-div button {
        width: 100%;
    }
}
@media (max-width:768px) and (min-width:575px) {
    .finance-cheque-field-after-cheque-outer-div {
        width: 99.2%;
    }
    .finance-save-button-div button {
        width: 100%;
    }
}
@media (max-width:991px) {
    .contact-person-details {
        margin-top: 1rem !important;
    }
    .finance-col-field-billing-contact {
        margin-top: 15px;
    }
}
.finance-save-button-div {
    display: flex;
    justify-content: center;
}
.finance-save-button-div .btn {
    width: 20%;
    margin-bottom: 1%;
}

.finance-outer-div-cart {
    padding-left: 20px;
    padding-right: 10px;
    margin-bottom: 5px;
}
.finance-inner-cart-div {
    padding: 1rem;
    border-radius: 0px;
    background-color:var(--white-color);
    border: 1px solid var(--step-icon-bg);
    border-radius: 10px;
}
.finance-outer-div-cart .row .col {
    padding-right: 15px;
    padding-left: 15px;
}
@media screen and (max-width:415px) {
    .finance-save-button-div .btn {
        width: 100%;
        margin-bottom: 1%;
    }
}
.eye-icon-mobile-view{
    display: none;
}
@media (max-width:768px) {
    .vendor-finance-web-eye{
        display: none;
    }
    .vendor-finance-img-inst-eye{
        display: flex;
        justify-content: space-between;
    }
    .eye-icon-mobile-view{
        display: block;
    }
    .finance-previewbutton-Div>.fa.fa-eye {
        font-size: 12px;
    }
    .box-content>.row>.mb-5{
        margin-bottom: 1.5rem !important;
    }
    .finance-cheque-field-after-cheque-text-div{
        margin-left: -24px;
    }
}
.hidden-input {
    display: none;
  }
  .custom-file-upload {
    display: inline-block;
    padding: 5px 20px;
    background-color: var(--sura-text-color);
    color: var(--white-text-color);
    border-radius: 5px;
    cursor: pointer;
  }
  .custom-file-upload:hover {
    background-color: var(--sura-text-color);

  }
.vendor-finance-file-name{
    font-size: 15px;
}
  .finance-cheque-field-after-cheque-outer-div{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
  }
  .finance-cheque-field-after-cheque-outer-div {
    display: flex;
    align-items: center; 
    border-radius: 6px;
  }
  .vendor-finance-file-name {
    flex-grow: 1; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }
  .preview-modal-img{
    height: 500px;
  }

  .finance-section .form-control {
       padding: .375rem .75rem;
  }